import template from "./template.hbs";
import Component from "../Component";

class Loader extends Component {
    
    constructor(parent) {
        
        super();

        this.createElement({parent, template})

        this.message = this.element.querySelector(".loader__message");
        this.progress = this.element.querySelector(".loader__progress-fill");
    }
    
    setProgress(percent) {
        this.progress.style.width = `${percent}%`;
    }

    setMessage(message) {
        this.message.innerHTML = message; 
    }

    close() {
        this.element.classList.add("loader--closing");
        window.setTimeout(() => {
            document.body.removeChild(this.element);
        }, 250);
    }
    
}

const loader = new Loader(document.body);
export default loader;