
<h1>{{name}}</h1>
{{!-- 
{{#if owner}}
<div class="info-panel__owner">
    Governed by the
    <div class="info-panel__owner-name">
        {{owner.species.name}}
    </div>
</div>
{{/if}}


{{#if nativeSpecies}}
<div class="info-panel__home">
    Home of the
    <div class="info-panel__home-name">
        {{nativeSpecies}}
    </div>
</div>
{{/if}} --}}


<table class="info-panel__table">
    
    {{#if owner}}
    <tr>
        <th>Governed By</th>
        <td>{{owner}}</td>
    </tr>
    {{/if}}
    {{#if nativeSpecies}}
    <tr>
        <th>Home Species</th>
        <td>{{nativeSpecies}}</td>
    </tr>
    {{/if}}
    {{#if owner}}
    <tr>
        <th>Control</th>
        <td>{{control}}</td>
    </tr>
    {{/if}}
</table>

{{!-- <hr> --}}
<h3>Primary Body</h3>

<table class="info-panel__table">
    <tr>
        <th>Class</th>
        <td>{{star.class}}</td>
    </tr>
    <tr>
        <th>Mass (kg)</th>
        <td>{{star.mass.value}}</td>
    </tr>
    <tr>
        <th>Radius (km)</th>
        <td>{{star.radius.value}}</td>
    </tr>
    <tr>
        <th>Temperature (K)</th>
        <td>{{star.temperature}}</td>
    </tr>
     <tr>
        <th>Orbital Bodies</th>
        <td>{{orbitals.length}}</td>
    </tr>
</table>
{{!-- <hr> --}}
<h3>Major Bodies</h3>
<table class="info-panel__table">
    {{#each orbitals}}
    <tr>
        <th>{{name}}</th>
        <td>{{class.name}}</td>
        {{!-- <td>{{#if populated}}Yes{{else}}No{{/if}}</td> --}}
    </tr>
    {{/each}}
    
</table>
{{!-- <hr> --}}
<h3>System Resources</h3>
<table class="info-panel__table">
    {{#each resources}}
    <tr>
        <th>{{name}}</th>
        <td>{{value}}</td>
        {{!-- <td>{{#if populated}}Yes{{else}}No{{/if}}</td> --}}
    </tr>
    {{/each}}
    
</table>