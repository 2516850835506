import track1 from "../../assets/music/sb_ascension.mp3";
import track2 from "../../assets/music/sb_eclipse.mp3";
import track3 from "../../assets/music/sb_extrapolation.mp3";
import track4 from "../../assets/music/sb_genesis.mp3";
import track5 from "../../assets/music/sb_lifeis.mp3";
import track6 from "../../assets/music/sb_lightindarkplaces.mp3";
import track7 from "../../assets/music/sb_tearsofadistantsun.mp3";
import track8 from "../../assets/music/sb_theoryofmachines.mp3";
import track9 from "../../assets/music/sb_titan.mp3";
import track10 from "../../assets/music/sb_discovery.mp3";
import track11 from "../../assets/music/sb_filaments.mp3";
import track12 from "../../assets/music/sb_monomyth_5_theexodus.mp3";
// import track13 from "../../assets/music/sb_red.mp3";
import track14 from "../../assets/music/sb_omega.mp3";
import track15 from "../../assets/music/sb_starsinherskies.mp3";

const tracklist = [
    { title: "Ascension", path: track1 },
    { title: "Eclipse", path: track2 },
    { title: "Extrapolation", path: track3 },
    { title: "Genesis", path: track4 },
    { title: "Life Is", path: track5 },
    { title: "Light in Dark Places", path: track6 },
    { title: "Tears of a Distant Sun", path: track7 },
    { title: "Theory of Machines", path: track8 },
    { title: "Titan", path: track9 },
    { title: "Discovery", path: track10 },
    { title: "Filaments", path: track11 },
    { title: "Monomyth - The Exodus", path: track12 },
    // { title: "Red", path: track13 },
    { title: "Omega", path: track14 },
    { title: "Stars in Her Skies", path: track15 }
];

export default tracklist;