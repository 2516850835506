{{#if owner}}
<div class="system-map-info__owner">
    Governed by the <span>{{owner.species.name}}</span>
</div>
{{/if}}

{{#if nativeSpecies}}
<div class="system-map-info__home">
    Home of the
    <div class="system-map-info__home-name">
        {{nativeSpecies.name}}
    </div>
</div>
{{/if}}

{{!--
<div class="system-map-info__header">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 470"
        xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 470 470">
        <g>
            <path
                d="m168.757,205.591l-7.525-2.445c-3.935-1.28-8.171,0.875-9.45,4.815-1.28,3.939 0.876,8.17 4.815,9.45l7.507,2.439c-1.044,4.889-1.604,9.954-1.604,15.149s0.561,10.261 1.604,15.149l-7.507,2.439c-3.939,1.28-6.096,5.511-4.815,9.45 1.029,3.169 3.97,5.185 7.131,5.185 0.769,0 1.55-0.119 2.319-0.369l7.525-2.445c4.179,9.375 10.278,17.71 17.814,24.488l-4.661,6.416c-2.435,3.351-1.692,8.042 1.659,10.476 1.331,0.967 2.873,1.433 4.402,1.433 2.318,0 4.606-1.072 6.073-3.092l4.657-6.411c8.637,5.018 18.389,8.319 28.798,9.394v7.888c0,4.142 3.357,7.5 7.5,7.5s7.5-3.358 7.5-7.5v-7.887c10.409-1.075 20.161-4.376 28.798-9.394l4.657,6.411c1.467,2.02 3.754,3.092 6.073,3.092 1.528,0 3.071-0.466 4.402-1.433 3.352-2.435 4.094-7.125 1.659-10.476l-4.661-6.416c7.536-6.778 13.635-15.113 17.814-24.488l7.524,2.445c0.77,0.25 1.551,0.369 2.319,0.369 3.161,0 6.102-2.015 7.131-5.184 1.28-3.939-0.876-8.171-4.815-9.451l-7.506-2.439c1.044-4.889 1.604-9.954 1.604-15.15s-0.561-10.261-1.604-15.15l7.506-2.439c3.939-1.28 6.096-5.511 4.815-9.451-1.279-3.94-5.514-6.095-9.45-4.815l-7.524,2.445c-4.179-9.375-10.278-17.71-17.814-24.488l4.661-6.416c2.435-3.351 1.692-8.042-1.659-10.476-3.348-2.434-8.041-1.693-10.476,1.66l-4.657,6.411c-8.637-5.018-18.389-8.319-28.798-9.394v-7.886c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v7.887c-10.409,1.075-20.161,4.376-28.798,9.394l-4.657-6.411c-2.434-3.351-7.124-4.093-10.476-1.66-3.352,2.435-4.094,7.125-1.659,10.476l4.661,6.416c-7.534,6.779-13.633,15.114-17.812,24.489zm66.243-28.091c31.706,0 57.5,25.794 57.5,57.5s-25.794,57.5-57.5,57.5-57.5-25.794-57.5-57.5 25.794-57.5 57.5-57.5z" />
            <path
                d="M401.17,68.83C356.784,24.444,297.771,0,235,0S113.216,24.444,68.83,68.83S0,172.229,0,235s24.444,121.784,68.83,166.17   S172.229,470,235,470c40.195,0,79.866-10.326,114.725-29.86c3.614-2.025,4.901-6.596,2.877-10.209s-6.595-4.902-10.21-2.876   C309.769,445.336,272.633,455,235,455c-121.309,0-220-98.691-220-220S113.691,15,235,15s220,98.691,220,220   c0,37.63-9.669,74.769-27.961,107.403c-2.025,3.613-0.738,8.184,2.875,10.209c3.611,2.023,8.184,0.739,10.209-2.875   C459.669,314.868,470,275.192,470,235C470,172.229,445.556,113.216,401.17,68.83z" />
            <path
                d="m395.867,355.867c-22.056,0-40,17.944-40,40s17.944,40 40,40 40-17.944 40-40-17.944-40-40-40zm0,65c-13.785,0-25-11.215-25-25s11.215-25 25-25 25,11.215 25,25-11.215,25-25,25z" />
            <path
                d="m120.979,349.021c31.437,31.436 72.729,47.154 114.021,47.154s82.585-15.718 114.021-47.154c62.871-62.872 62.871-165.17 0-228.042-45.445-45.445-113.45-59.574-173.251-36-0.956,0.377-1.827,0.949-2.555,1.676-2.929,2.929-2.928,7.677 0.001,10.605 2.221,2.22 5.486,2.757 8.215,1.61 54.205-21.297 115.805-8.464 156.982,32.714 57.023,57.023 57.023,149.806 0,206.829-28.515,28.515-65.958,42.771-103.414,42.767-37.447-0.003-74.906-14.259-103.414-42.767-41.217-41.217-54.03-102.898-32.645-157.141 1.52-3.854-0.373-8.209-4.227-9.729-3.855-1.519-8.21,0.374-9.729,4.227-23.575,59.804-9.447,127.809 35.995,173.251z" />
            <path
                d="m126.282,86.282c-10.685,0-20.729,4.161-28.283,11.716-7.556,7.555-11.717,17.6-11.717,28.284s4.161,20.729 11.716,28.284c7.555,7.555 17.6,11.716 28.284,11.716s20.729-4.161 28.285-11.716c15.595-15.596 15.595-40.972-0.001-56.568-7.554-7.555-17.599-11.716-28.284-11.716zm17.678,57.678c-4.722,4.722-11,7.323-17.678,7.323s-12.955-2.601-17.678-7.323c-4.722-4.722-7.322-11-7.322-17.678s2.601-12.956 7.323-17.678c4.722-4.722 10.999-7.322 17.677-7.322s12.956,2.601 17.678,7.322c9.747,9.748 9.747,25.608 0,35.356z" />
        </g>
    </svg>
    {{#if system.name}}
    {{system.name}}
    {{else}}
    {{system.catalogName}}
    {{/if}}
</div>


<table class="system-map-info__table">
    <tr>
        <th>Major Bodies</th>
        <td>{{system.planets.length}}</td>
    </tr>
    <tr>
        <th>Starlanes</th>
        <td>{{system.connections.length}}</td>
    </tr>
</table> --}}


<div class="system-map-info__section">
    <div class="system-map-info__header">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <g>
                <g>
                    <path d="M255.999,83.867c-94.915,0-172.132,77.219-172.132,172.132s77.218,172.132,172.132,172.132
			s172.132-77.218,172.132-172.132S350.914,83.867,255.999,83.867z M255.999,412.924c-86.529,0-156.924-70.396-156.924-156.924
			S169.47,99.075,255.999,99.075s156.924,70.395,156.924,156.924S342.528,412.924,255.999,412.924z" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M510.526,301.328l-33.458-45.604c-5.898-8.04-6.59-18.872-1.763-27.596l27.379-49.494c1.035-1.87,1.235-4.089,0.551-6.114
			c-0.684-2.025-2.188-3.668-4.144-4.528l-51.778-22.765c-9.128-4.013-15.143-13.048-15.326-23.018l-1.035-56.552
			c-0.04-2.136-0.975-4.158-2.579-5.57c-1.604-1.411-3.727-2.083-5.852-1.849l-56.225,6.173
			c-9.909,1.087-19.638-3.728-24.781-12.271L312.341,3.682c-1.102-1.831-2.924-3.114-5.019-3.533s-4.27,0.06-5.994,1.325
			l-45.605,33.457c-8.039,5.898-18.872,6.59-27.597,1.763L178.632,9.315c-1.871-1.035-4.088-1.235-6.114-0.551
			c-2.025,0.683-3.668,2.188-4.528,4.144l-22.765,51.777c-4.013,9.128-13.048,15.144-23.018,15.327l-56.552,1.036
			c-2.136,0.04-4.158,0.975-5.57,2.579c-1.411,1.605-2.082,3.729-1.849,5.853l6.173,56.224c1.088,9.911-3.728,19.638-12.271,24.782
			L3.682,199.658c-1.831,1.102-3.114,2.924-3.533,5.019c-0.42,2.095,0.061,4.27,1.324,5.994l33.458,45.604
			c5.899,8.04,6.59,18.872,1.764,27.597L9.316,333.365c-1.035,1.87-1.235,4.089-0.55,6.114c0.684,2.025,2.188,3.668,4.144,4.528
			l51.778,22.766c9.128,4.013,15.144,13.048,15.327,23.018l1.035,56.552c0.04,2.136,0.975,4.158,2.579,5.57
			c1.605,1.411,3.728,2.087,5.853,1.849l56.224-6.173c9.903-1.091,19.638,3.728,24.782,12.271l29.173,48.458
			c1.102,1.831,2.924,3.114,5.019,3.533c0.497,0.099,0.997,0.148,1.495,0.148c1.605,0,3.183-0.508,4.498-1.473l45.605-33.458
			c8.04-5.898,18.871-6.59,27.597-1.763l49.493,27.379c1.869,1.035,4.087,1.234,6.114,0.55c2.025-0.683,3.668-2.188,4.528-4.144
			l22.766-51.778c4.013-9.127,13.048-15.144,23.018-15.327l56.552-1.035c2.136-0.039,4.158-0.975,5.57-2.579
			c1.411-1.605,2.082-3.729,1.849-5.852l-6.173-56.225c-1.088-9.911,3.728-19.638,12.271-24.781l48.458-29.173
			c1.831-1.102,3.114-2.924,3.533-5.019C512.271,305.228,511.79,303.051,510.526,301.328z M452.015,328.484
			c-13.606,8.191-21.278,23.684-19.544,39.471l5.264,47.943l-48.223,0.883c-15.878,0.291-30.269,9.873-36.661,24.411l-19.412,44.153
			l-42.204-23.347c-13.897-7.689-31.15-6.586-43.955,2.809l-38.888,28.531l-24.877-41.322
			c-7.423-12.331-20.842-19.787-35.043-19.787c-1.469,0-2.947,0.08-4.427,0.242L96.1,437.736l-0.883-48.223
			c-0.291-15.879-9.872-30.269-24.411-36.661l-44.152-19.413l23.347-42.204c7.688-13.897,6.585-31.15-2.809-43.955l-28.53-38.889
			l41.321-24.876c13.606-8.191,21.278-23.684,19.544-39.471L74.264,96.1l48.223-0.883c15.878-0.291,30.269-9.872,36.661-24.411
			l19.412-44.152l42.204,23.347c13.897,7.687,31.15,6.585,43.955-2.809l38.888-28.53l24.877,41.321
			c8.191,13.606,23.684,21.278,39.47,19.544l47.944-5.264l0.883,48.223c0.29,15.879,9.872,30.269,24.41,36.661l44.153,19.412
			l-23.347,42.204c-7.688,13.896-6.585,31.149,2.809,43.954l28.531,38.889L452.015,328.484z" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M255.999,138.335c-64.881,0-117.665,52.785-117.665,117.665c0,4.199,3.405,7.604,7.604,7.604s7.604-3.405,7.604-7.604
			c0-56.495,45.961-102.457,102.457-102.457c4.199,0,7.604-3.405,7.604-7.604S260.199,138.335,255.999,138.335z" />
                </g>
            </g>
        </svg>
        {{#if system.name}}
        {{system.name}}
        {{else}}
        {{system.catalogName}}
        {{/if}}
    </div>

    <table class="system-map-info__table">
        <tr>
            <th>Class</th>
            <td>{{system.star.class}}</td>
        </tr>
        <tr>
            <th>Radius</th>
            <td>{{system.star.radius.value}} km</td>
        </tr>
        <tr>
            <th>Mass</th>
            <td>{{system.star.mass.value}} kg</td>
        </tr>
        <tr>
            <th>Luminosity</th>
            <td>{{system.star.luminosity.exponential}} W</td>
        </tr>
        <tr>
            <th>Temperature</th>
            <td>{{system.star.temperature}} K</td>
        </tr>
    </table>
</div>

<div class="system-map-info__section">
    {{#if object}}
    <div class="system-map-info__header">
        <svg viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M242.31543,62c-6.83887-11.84521-27.13916-13.99414-57.25342-6.11353a91.983,91.983,0,0,0-148.08349,85.512C15.12646,163.52246,6.85059,182.1626,13.68457,194c4.14941,7.18652,13.04834,10.81934,26.17481,10.81934a115.26027,115.26027,0,0,0,24.7749-3.167c2.061-.4624,4.16894-.989,6.29687-1.54443A91.98221,91.98221,0,0,0,219.02,114.593c1.53174-1.55249,3.02881-3.10181,4.44775-4.64282C242.17969,89.63379,248.69678,73.05371,242.31543,62ZM128,44a84.13109,84.13109,0,0,1,82.42822,67.86206C193.8833,127.96582,171.39453,144.51514,146,159.17676c-25.37109,14.64819-50.92383,25.84179-73.12793,32.12109A83.94418,83.94418,0,0,1,128,44ZM62.88281,193.84668c-22.20166,4.98144-38.00342,3.543-42.27-3.84668-4.3291-7.49927,2.63477-22.1582,18.28614-39.04517A92.033,92.033,0,0,0,63.63574,193.667C63.38672,193.72388,63.13086,193.791,62.88281,193.84668ZM128,212a83.51135,83.51135,0,0,1-47.3623-14.67139c21.48046-6.67724,45.53271-17.46606,69.3623-31.22412,23.83545-13.76074,45.20947-29.20093,61.73438-44.46875.1582,2.10352.26562,4.221.26562,6.36426A84.0953,84.0953,0,0,1,128,212ZM192.36133,62.33008c22.48584-5.12842,38.6914-3.83643,43.02588,3.66992,4.2666,7.38965-2.38916,21.79395-17.80371,38.53027-.15625.16968-.32569.34107-.4834.51075A92.03436,92.03436,0,0,0,192.36133,62.33008Z" />
        </svg>
        {{#if object.name}}{{object.name}}{{else}}Unclaimed{{/if}}
    </div>

    <table class="system-map-info__table">
        <tr>
            <th>Class</th>
            <td>{{object.class}}</td>
        </tr>
        <tr>
            <th>Radius</th>
            <td>{{object.radiusKM}} km</td>
        </tr>
        <tr>
            <th>Axial Tilt</th>
            <td>{{object.axialTilt}}&deg;</td>
        </tr>
        <tr>
            <th>Temperature</th>
            <td>{{object.temperature}} K</td>
        </tr>
        <tr>
            <th>Populated</th>
            <td>{{#if object.populated}}Yes{{else}}No{{/if}}</td>
        </tr>
    </table>
    {{/if}}
</div>