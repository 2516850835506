import template from "./template.hbs";
import Component from "../Component";
import { toggleFullscreen } from "../../js/utilities";

export default class MainPanel extends Component {

    constructor(parent) {

        super();

        this.createElement({ parent, template })

        this.bindEvents();

    }

    bindEvents() {

        this.element.querySelector(".fullscreen-button").addEventListener("click", e => {

            toggleFullscreen();
            
            // let body = document.body;
            // var fullscreenRequest = body.requestFullScreen || body.webkitRequestFullScreen || body.mozRequestFullScreen || body.msRequestFullScreen;
            // if (fullscreenRequest) {
            //     fullscreenRequest.call(body);
            // }
        });

    }

    updateMessages() {
        
    }

}

