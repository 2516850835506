import { gameData } from "../gameData";
import { randfloat, randint } from "../utilities";

export default class Species {
    constructor(civilization, homeworld) {
        
        this.civilization = civilization;
        this.homeworld = homeworld;
        
        this.name = this.civilization.language.translate("People");
        
        this.biology = {
            virility: randint(1,10),
            longevity: randint(1,10),
            hardiness: randint(1,10),
            health: randint(1,10)
        }
        
        //HEXACO
        this.psychology = {
            openness: randint(1,10), // High: intellectual, creative, unconventional, innovative, ironic --- Low: shallow, unimaginative, conventional
            conscientiousness: randint(1,10), // High: organized, disciplined, diligent, careful, thorough, precise --- Low: sloppy, negligent, reckless, lazy, irresponsible, absent-minded
            extraversion: randint(1,10), // High: Outgoing, lively, extraverted, sociable, talkative, cheerful, active --- Low: shy, passive, withdrawn, introverted, quiet, reserved
            agreeableness: randint(1,10), // High: patient, tolerant, peaceful, mild, agreeable, lenient, gentle --- Low: ill-tempered, quarrelsome, stubborn, choleric
            emotionality: randint(1,10), // High: Emotional, oversensitive, sentimental, fearful, anxious, vulnerable --- Low: brave, tough, independent, self-assured, stable !!!! INVERT
            honesty: randint(1,10) // High: Sincere, honest, faithful, loyal, modest/unassuming --- Low: sly, deceitful, greedy, pretentious, hypocritical, boastful, pompous
        }
        
        this.skills = {
            economics: [
                [this.psychology.conscientiousness, 1],
                [this.psychology.openness, 1],
                [this.psychology.honesty, 0.5, true],
                [randint(0,10), 0.5] // NATURAL APTITUDE
            ],
            politics: [
                [this.psychology.extraversion, 1],
                [this.psychology.emotionality, 1, true],
                [randint(0,10), 0.5] // NATURAL APTITUDE
            ],
            military: [
                [this.psychology.openness, 0.5],
                [this.psychology.emotionality, 1, true],
                [this.psychology.agreeableness, 0.5, true],
                [randint(0,10), 0.5] // NATURAL APTITUDE
            ],
            science: [
                [this.psychology.openness, 1],
                [this.psychology.emotionality, 1, true],
                [this.psychology.conscientiousness, 1],
                [randint(0,10), 0.5] // NATURAL APTITUDE
            ],
            leadership: [
                [this.psychology.agreeableness, 0.5],
                [this.psychology.extraversion, 0.75],
                [this.psychology.conscientiousness, 1],
                [this.psychology.emotionality, 1, true],
                [randint(0,10), 0.5] // NATURAL APTITUDE
            ],
            subterfuge: [
                [this.psychology.honesty, 1, true],
                [this.psychology.extraversion, 0.5, true],
                [this.psychology.conscientiousness, 1],
                [this.psychology.emotionality, 1, true],
                [this.psychology.agreeableness, 0.75],
                [randint(0,10), 0.5] // NATURAL APTITUDE
            ]
        }
        
        this.initSkills();

        // console.log(this.skills);
        
        this.environment = {
            ideal: Math.floor(this.homeworld.temperature),
            min: Math.floor(Math.floor(this.homeworld.temperature) * (2-(1+(this.biology.hardiness/100)))),
            max: Math.floor(Math.floor(this.homeworld.temperature) * this.biology.hardiness)
        }
        
        gameData.species.push(this);

    }
    
    initSkills() {
        
        for (let key in this.skills) {
            let value = 0;
            let max = 0;
            
            for (let aspect of this.skills[key]) {
                max += ( 10 * aspect[1] );
                value += (aspect[2]) ? ( (10 * aspect[1]) - (aspect[0] * aspect[1] ) ) : (aspect[0] * aspect[1]);
            }
            
            this.skills[key] = Math.ceil(10 * (value / max))
        }
        
        // How much natural luck does this species have.
        // If 0, all are equal. Luck could be a representation of general "ability" too though...
        this.skills.luck = 0;

    }

    tick() {

    }
    
}
