import latinize from 'latinize';
import { randint, randfloat, shuffle, commonWords, randnormal, rng } from "../../utilities";

import arabic from "./languages/arabic.json";
import chinese from "./languages/chinese.json";
import dutch from "./languages/dutch.json";
import english from "./languages/english.json";
import french from "./languages/french.json";
import german from "./languages/german.json";
import italian from "./languages/italian.json";
import japanese from "./languages/japanese.json";
import spanish from "./languages/spanish.json";
import turkish from "./languages/turkish.json";
import welsh from "./languages/welsh.json";

const wordparts = [
    arabic,
    // chinese,
    dutch,
    english,
    french,
    german,
    italian,
    japanese,
    spanish,
    turkish,
    welsh
]

const vowels = ['a', 'e', 'i', 'o', 'u', 'y'];
const consonants = ['b', 'c', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'v', 'w', 'x', 'z'];
const separators = ["'", "-", '`'];

export default class Language {
    constructor() {


        this.parameters = {
            setSamples: randint(2, 5), // number of slices to take from each set
            bigramCount: randint(20, 30),
            trigramCount: randint(10, 30),
            quadgramCount: randint(3, 10),
            averageWordLength: randint(3, 5),
            vowelPairTolerance: randfloat(0.1, 0.9),
            consonantPairTolerance: randfloat(0.1, 0.9),
            matchingPairTolerance: randfloat(0.1, 0.9),
            firstSoundPreference: randfloat(0.1, 0.9),
            lastSoundPreference: randfloat(0.1, 0.9),
            compoundWordChance: randfloat(0.01, 0.1)
        };


        // customized
        // this.parameters = {
        //     setSamples: randint(2,4), // number of slices to take from each set
        //     bigramCount: randint(10, 20),
        //     trigramCount: randint(20, 40),
        //     quadgramCount: randint(3, 10),
        //     averageWordLength: randint(3, 6),
        //     vowelPairTolerance: randfloat(0.1, 0.9),
        //     consonantPairTolerance: randfloat(0.1, 0.9),
        //     matchingPairTolerance: randfloat(0.1, 0.9),
        //     firstSoundPreference: randfloat(0.1, 0.9),
        //     lastSoundPreference: randfloat(0.1, 0.9),
        //     compoundWordChance: randfloat(0.01, 0.4)
        // };

        this.initWordParts();
        this.separator = separators.sort(() => rng() - rng()).slice(0, 1)
        this.lexicon = {};
        this.existingWords = [];

        // this.initLexicon();

        this.name = this.translate("Language");

        // console.log(this.name)
        // console.log(this.parameters)

    }

    initWordParts() {

        this.collection = wordparts[Math.floor(rng() * wordparts.length)]

        // this.bigrams = this.collection.bigrams.sort(() => rng() - rng()).slice(0, this.parameters.bigramCount)
        // this.trigrams = this.collection.trigrams.sort(() => rng() - rng()).slice(0, this.parameters.trigramCount)
        // this.quadgrams = this.collection.quadgrams.sort(() => rng() - rng()).slice(0, this.parameters.quadgramCount)

        // Sorted
        // let bigramSlice = randint(0, this.collection.bigrams.length - this.parameters.bigramCount - 1);
        // let trigramSlice = randint(0, this.collection.trigrams.length - this.parameters.trigramCount - 1);
        // let quadgramSlice = randint(0, this.collection.quadgrams.length - this.parameters.quadgramCount - 1);

        this.collection.bigrams.sort();
        this.collection.trigrams.sort();
        this.collection.quadgrams.sort();

        this.bigrams = [];
        this.trigrams = [];
        this.quadgrams = [];

        for (let i = 0; i < this.parameters.setSamples; i++) {
            let sliceLength = Math.ceil(this.parameters.bigramCount / this.parameters.setSamples);
            let sliceStart = randint(0, this.collection.bigrams.length - sliceLength - 1);
            let slice = this.collection.bigrams.slice(sliceStart, sliceStart + sliceLength);
            this.bigrams = this.bigrams.concat(slice);
        }

        for (let i = 0; i < this.parameters.setSamples; i++) {
            let sliceLength = Math.ceil(this.parameters.trigramCount / this.parameters.setSamples);
            let sliceStart = randint(0, this.collection.trigrams.length - sliceLength - 1);
            let slice = this.collection.trigrams.slice(sliceStart, sliceStart + sliceLength);
            this.trigrams = this.trigrams.concat(slice);
        }

        for (let i = 0; i < this.parameters.setSamples; i++) {
            let sliceLength = Math.ceil(this.parameters.quadgramCount / this.parameters.setSamples);
            let sliceStart = randint(0, this.collection.quadgrams.length - sliceLength - 1);
            let slice = this.collection.quadgrams.slice(sliceStart, sliceStart + sliceLength);
            this.quadgrams = this.quadgrams.concat(slice);
        }


        // this.bigrams = this.collection.bigrams.sort().slice(bigramSlice, bigramSlice + this.parameters.bigramCount)
        // this.trigrams = this.collection.trigrams.sort().slice(trigramSlice, trigramSlice + this.parameters.trigramCount)
        // this.quadgrams = this.collection.quadgrams.sort().slice(quadgramSlice, quadgramSlice + this.parameters.quadgramCount)

        // Shuffled
        // this.bigrams = shuffle(this.collection.bigrams).slice(0, this.parameters.bigramCount)
        // this.trigrams = shuffle(this.collection.trigrams).slice(0, this.parameters.trigramCount)
        // this.quadgrams = shuffle(this.collection.quadgrams).slice(0, this.parameters.quadgramCount)

        this.wordparts = [].concat(this.bigrams, this.trigrams, this.quadgrams);
    }

    createSentence() {
        let sentence = "";
        for (let i = randint(3, 10); i--;) {
            sentence += this.createWord();
            sentence += i > 0 ? " " : ".";
        }

        return sentence.charAt(0).toUpperCase() + sentence.slice(1);

    }


    createWord(isCompound) {

        let word = "";
        let compound = false;

        // Select a target length for the word from a normal distribution centered on the average length
        let targetLength = Math.floor(randnormal(2, (this.parameters.averageWordLength * 2) - 2, 1));
        // let targetLength = this.parameters.averageWordLength + randint(-2, 2);

        // While the word is shorter than the target length, add more parts
        while (word.length < targetLength) {

            let part = "";

            while (!part) {

                // Select a random word part then convert to lowercase
                part = this.wordparts[randint(0, this.wordparts.length - 1)];
                part = part.toLowerCase();

                // Get the letters we need to test
                let firstLetter = latinize(part.slice(0, 1));
                let secondLetter = latinize(part.slice(1, 2));
                let lastLetter = latinize(part[part.length - 1]);
                let wordEnding = latinize(word[word.length - 1])

                // Check if each letter is a vowel or not
                let firstIsVowel = vowels.includes(firstLetter);
                let secondIsVowel = vowels.includes(secondLetter);
                let lastIsVowel = vowels.includes(lastLetter);
                let wordEndingIsVowel = vowels.includes(wordEnding);

                // Check the preferences (NORMAL DISTRIBUTION )
                // let startWithVowel = randnormal(0,1) <= this.parameters.firstSoundPreference;
                // let endWithVowel = randnormal(0,1) <= this.parameters.lastSoundPreference;
                // let banVowelPairs = randnormal(0,1) > this.parameters.vowelPairTolerance;
                // let banConsonantPair = randnormal(0,1) > this.parameters.consonantPairTolerance;
                // let banMatchingPair = randnormal(0,1) > this.parameters.matchingPairTolerance;    

                // Check Preferences
                let startWithVowel = rng() <= this.parameters.firstSoundPreference;
                let endWithVowel = rng() <= this.parameters.lastSoundPreference;
                let banVowelPairs = rng() > this.parameters.vowelPairTolerance;
                let banConsonantPair = rng() > this.parameters.consonantPairTolerance;
                let banMatchingPair = rng() > this.parameters.matchingPairTolerance;

                // If word is blank, we're dealing with the first part of the word.
                if (!word) {

                    if (!firstIsVowel && !secondIsVowel) {
                        part = "";
                    }

                    if (
                        (startWithVowel && !firstIsVowel) || // We want to start with a vowel and it's not
                        (!startWithVowel && firstIsVowel) || // We want to start with a consonant and it's not
                        (banVowelPairs && firstIsVowel && secondIsVowel) || // Vowel pairs banned and the first letters are vowels
                        (banConsonantPair && !firstIsVowel && !secondIsVowel) || // Consonant pairs banned and the first letters are consonants
                        (banMatchingPair && firstLetter == secondLetter) // Matching letters banned and the first letters match
                    ) {
                        part = "";
                    }

                    if (
                        (part.length >= targetLength) && // This will be the last part
                        (
                            (endWithVowel && !lastIsVowel) || // Random num picked a vowel but letter is consonant
                            (!endWithVowel && lastIsVowel)  // Random num picked a consonant but letter is vowel
                        )
                    ) {
                        part = "";
                    }

                } else {

                    if (lastIsVowel == firstIsVowel) {
                        part = "";
                    }

                    if (
                        (banVowelPairs && firstIsVowel && lastIsVowel) || // Vowel pairs banned and the first letters are vowels
                        (banConsonantPair && !firstIsVowel && !lastIsVowel) || // Consonant pairs banned and the first letters are consonants
                        (banMatchingPair && firstLetter == lastLetter) // Matching letters banned and the first letters match
                    ) {
                        part = "";
                    }

                    if (
                        (word.length + part.length >= targetLength) && // This will be the last part
                        (
                            (endWithVowel && !lastIsVowel) || // Random num picked a vowel but letter is consonant
                            (!endWithVowel && lastIsVowel)  // Random num picked a consonant but letter is vowel
                        )
                    ) {
                        part = "";
                    }
                }

                if (rng() < this.parameters.compoundWordChance && !compound && part.length) {
                    word += part + this.separator;
                    part = ""
                    compound = true;
                }


            }

            word += part;

        }


        word = word.toLowerCase();
        word = this.existingWords.includes(word) ? this.createWord() : word;
        this.existingWords.push(word);
        return word;

    }

    initLexicon() {
        for (let word of commonWords) {
            this.lexicon[word] = this.createWord();
        }
    }

    translate(text) {
        let sentences = text.split(/([\.\?\!\,\:\;\-])/);
        if (sentences.length > 1) sentences.pop();

        let translated = "";

        for (let sentence of sentences) {
            if (sentence.search(/^[\.\?\!\,\:\;\-]/gi) != -1) {
                translated += sentence + " ";
            } else {
                let words = sentence.split(" ");
                for (let word of words) {
                    if (word == "") continue;
                    let lcaseWord = word.toLowerCase();
                    if (!this.lexicon[lcaseWord]) {
                        this.lexicon[lcaseWord] = this.createWord();
                    }

                    let translatedWord = this.lexicon[lcaseWord];
                    translated += (word[0] === word[0].toUpperCase()) ? translatedWord[0].toUpperCase() + translatedWord.slice(1) : translatedWord;
                    if (word != words[words.length - 1]) { translated += " "; }
                }
            }
        }
        return translated;
    }

}


if (location.search == "?langdemo") {
    for (let i = 0; i < 3; i++) {
        let language = new Language();

        // console.log(language.lexicon)

        let list = document.createElement("div");
        list.style.float = "left";
        list.style.margin = "10px"
        list.style.padding = "0 10px"
        list.style.border = "1px #000 solid"
        list.style.width = "500px";

        let header = document.createElement("h1")
        header.innerHTML = language.name;
        list.appendChild(header);

        let subheader = document.createElement("h3")
        subheader.innerHTML = `Based on ${language.collection.language.replace(/\b\w/g, c => c. toUpperCase())}`;
        list.appendChild(subheader);

        let item = document.createElement("table");
        for (let key in language.parameters) {
            item.innerHTML += `<tr><td>${key}</td><td>${language.parameters[key].toFixed(2)}</td></tr>`
        }

        /*
        item.innerHTML = `
            Average Word Length: ${language.parameters.averageWordLength}<br/>
            Compound Words Allowed: ${language.parameters.allowCompoundWords} (${Math.floor(language.parameters.compoundWordChance * 100)}%)<br />
            Start Vowel Preference: ${Math.floor(language.parameters.startVowelPreference * 100)}%<br />
            End Vowel Preference: ${Math.floor(language.parameters.endVowelPreference * 100)}%<br />
        `;
        */


        list.appendChild(item);

        list.appendChild(document.createElement("hr"))

        for (let j = 10; j--;) {
            let item = document.createElement("span");
            item.innerHTML = language.createWord() + ", ";
            list.appendChild(item);
        }

        list.appendChild(document.createElement("hr"))

        for (let j = 5; j--;) {
            let item = document.createElement("p");
            item.innerHTML = language.createSentence();
            list.appendChild(item);
        }

        list.appendChild(document.createElement("hr"))

        item = document.createElement("p");
        item.innerHTML = `<em>Home</em><br />${language.translate("Home")}`;
        list.appendChild(item);

        item = document.createElement("p");
        item.innerHTML = `<em>People</em><br />${language.translate("People")}`;
        list.appendChild(item);

        item = document.createElement("p");
        item.innerHTML = `<em>We are peaceful.</em><br />${language.translate("We are peaceful.")}`;
        list.appendChild(item);

        item = document.createElement("p");
        item.innerHTML = `<em>Resistance is futile!</em><br />${language.translate("Resistance is futile!")}`;
        list.appendChild(item);

        item = document.createElement("p");
        item.innerHTML = `<em>We are a peaceful people visiting your home.</em><br />${language.translate("We are a peaceful people visiting your home.")}`;
        list.appendChild(item);

        item = document.createElement("p");
        item.innerHTML = `<em>We have traveled a great distance and are looking forward to making new friends.</em><br />${language.translate("We have traveled a great distance and are looking forward to making new friends.")}`;
        list.appendChild(item);

        item = document.createElement("p");
        item.innerHTML = `<em>Hello, how are you? We are very happy to meet you and look forward to discovering how your society works. We are hopeful that you are also peaceful and will welcome us to your home.</em><br />${language.translate("Hello, how are you? We are very happy to meet you and look forward to discovering how your society works. We are hopeful that you are also peaceful and will welcome us to your home.")}`;
        list.appendChild(item);

        document.body.appendChild(list);

    }
}