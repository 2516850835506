import { chooseRandom, randint } from "../../utilities";

export default class Government {

    constructor(species) {
        let format = chooseRandom([
            'Imperium',
            'Republic',
            'Kingdom',
            'Principality',
            'Empire',
            'Sovereignty',
            'Free Republic',
            'Democratic State',
            'Democratic Kingdom',
            'Elective Monarchy'
        ])

        this.name = randint(0,1) ? `${species.name} ${format}` : `${format} of ${species.name}`;

    }

}