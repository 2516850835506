const mapSizes = {
    huge: {
        label: "Huge",
        width: 10000,
        height: 10000,
        cellScale: 24,
        systems: 800,
        minSpace: 200,
        minConnections: 3,
        maxConnections: 8
    },
    large: {
        label: "Large",
        width: 8000,
        height: 8000,
        cellScale: 16,
        systems: 500,
        minSpace: 200,
        minConnections: 2,
        maxConnections: 6
    },
    medium: {
        label: "Medium",
        width: 3000,
        height: 3000,
        cellScale: 8,
        systems: 250,
        minSpace: 95,
        minConnections: 2,
        maxConnections: 6
    },    
    small: {
        label: "Small",
        width: 1000,
        height: 1000,
        cellScale: 4,
        systems: 50,
        minSpace: 100,
        minConnections: 2,
        maxConnections: 4
    },
    tiny: {
        label: "Tiny",
        width: 600,
        height: 600,
        cellScale: 2,
        systems: 25,
        minSpace: 100,
        minConnections: 2,
        maxConnections: 4
    }
};

const defaultConfig = {
    map: {
        size: "medium",
        spacingPasses: 100,
        scrollSpeed: 30,
        zoomRate: 0.05,
        dragSensitivity: 1,
        scale: 1,
        voronoi: false
    },
    systemMap: {
        // width: 3840, // 4k
        // height: 2160,
        width: 10000,
        height: 10000,
        spacingPasses: 100,
        scrollSpeed: 30,
        zoomRate: 0.05,
        dragSensitivity: 2,
        scale: 1,
        voronoi: false
    },
    systems: {
        centralBodies: {
            types: {
                mainSequenceStars: true,
                blackHoles: true,
                exoticStars: true,
                novas: false,
            }
        },
        orbitals: {
            likelihood: 0.2,
            maxCount: 20,
            maxSatellites: 20,
            ringChance: 0.33,
            types: {
                planets: true,
                asteroids: true,
                planetoids: true
            }
        },
        habitableZone: {
            maxTemp: 366,
            minTemp: 173,
            idealTemp: 288,
            rangeOffset: 4
        }
    },
    life: {
        likelihood: 0.2,
        minTemp: 200,
        maxTemp: 400
    },
    resources: {
        mineral: {
            regenerationAllowed: false,
            depletionAllowed: false
        },
        gas: {
            regenerationAllowed: false,
            depletionAllowed: false
        },
        food: {
            regenerationAllowed: false,
            depletionAllowed: false
        },
        exotic: {
            regenerationAllowed: false,
            depletionAllowed: false
        },
        research: {
            regenerationAllowed: false,
            depletionAllowed: false
        }
    },
    time: {
        ticksPerDay: 1,
        daysPerWeek: 5,
        weekPerMonth: 5,
        monthsPerYear: 10
    }
}

class ConfigManager {
    
    constructor() {
        this.load();
    }
    
    load() {
        let config = defaultConfig;
        let storedConfig = false //localStorage.getItem("config");
        
        if (storedConfig) { Object.assign(config, JSON.parse(storedConfig)) }
        
        for (let key in config) {
            this[key] = config[key];
        }

        this.updateMapSettings();

    }
    
    save() {
        localStorage.setItem("config", JSON.stringify(this))
    }
    
    updateMapSettings() {
        Object.assign(this.map, mapSizes[this.map.size])
        mapSizes[this.map.size];
    }
    
}

var config = new ConfigManager();

export default config;