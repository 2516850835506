import Species from "./Species";
import Language from "./culture/LanguageImproved";
import { randint, shuffle, toTitleCase, rng, getIncrementalColor } from "../utilities";
import Character from "./Character";
import Leader from "./government/Leader";
import { gameData } from "../gameData";
import Government from "./government/Government";

export default class Civilization {

    constructor(homeworld) {
        this.homeworld = homeworld;
        this.language = new Language();
        this.species = new Species(this, homeworld);
        this.government = new Government(this.species);

        this.homeworld.system.nativeSpecies = this.species;

        this.leaders = {
            ruler: null,
            economist: null,
            commander: null,
            diplomat: null,
            spymaster: null,
            researcher: null
        }

        this.characters = [

        ]

        this.actions = []
        
        this.color = this.selectColor();

        this.systems = [];
        this.init()

        gameData.civilizations.push(this);

    }

    init() {
        this.homeworld.system.name = this.language.translate("Home");
        this.homeworld.populated = true;
        this.homeworld.system.populated = true;
        this.homeworld.name = this.language.translate("Earth")
        this.homeworld.system.owner = this;
        this.systems.push(this.homeworld.system);

        for (let orbital of this.homeworld.system.orbitals) {
            if (orbital != this.homeworld) {
                orbital.name = toTitleCase(this.language.createWord());
            }
        }

        for (let i = 0; i < 20; i++) {
            this.characters.push(new Character(this));
        }

        this.initLeaders();
    }

    initLeaders() {

        // this.positions.ruler = new Leader(this.characters[0]);

        this.sortCharactersBySkill("economics");
        this.leaders.economist = this.characters.filter(character => character.employed === false)[0];
        this.leaders.economist.employed = true;

        this.sortCharactersBySkill("military");
        this.leaders.commander = this.characters.filter(character => character.employed === false)[0];
        this.leaders.commander.employed = true;

        this.sortCharactersBySkill("politics");
        this.leaders.diplomat = this.characters.filter(character => character.employed === false)[0];
        this.leaders.diplomat.employed = true;

        this.sortCharactersBySkill("charisma");
        this.leaders.spymaster = this.characters.filter(character => character.employed === false)[0];
        this.leaders.spymaster.employed = true;

        this.sortCharactersBySkill("science");
        this.leaders.researcher = this.characters.filter(character => character.employed === false)[0];
        this.leaders.researcher.employed = true;


    }

    selectColor() {

        // return {
        //     fill: { h: Math.floor(rng() * 360), s: '60%', l: '30%' },
        //     outline: { h: Math.floor(rng() * 360), s: '60%', l: '30%' }
        // }

        // let color = false;
        // while (!color) {
        //     color = { h: Math.floor(rng() * 360), s: '60%', l: '30%' };
        //     let invalid = gameData.civilizations.some( civ => civ.color.h > color.h - 10 && civ.color.h < color.h + 10 )
        //     color = invalid ? false : color;
        // }
        return { h: getIncrementalColor(), s: '60%', l: '30%' };
    }

    sortCharactersBySkill(skill) {
        this.characters.sort((a, b) => {
            if (a.skills[skill] > b.skills[skill]) return -1;
            if (a.skills[skill] < b.skills[skill]) return 1;
            return 0;
        })
    }

    claimSystem(system) {
        system.owner = this;
        system.control = 100;
        if (!system.name) system.name = this.language.createWord();
        // system.name = this.language.createWord();
        this.systems.push(system);
    }

    abandonSystem(system) {
        // Make sure this civ is the current owner.
        // If so, remove system from it's list.
        if (system.owner == this) {
            var index = this.systems.indexOf(system);
            if (index !== -1) { this.systems.splice(index, 1); }
        }
    }

    tick() {

        let system = this.systems[Math.floor(rng() * this.systems.length)]; 

        if (system) {
            let connection = system.connections[Math.floor(rng() * system.connections.length)]; 
            if (connection) {
                if (connection.owner != this) {
                    if (connection.owner == null) {
                        this.claimSystem(connection);
                    } else {
                        let defenderRoll = connection.owner.leaders.commander.getSkill("military") + randint(0, connection.owner.leaders.commander.getSkill("luck")) ;
                        let attackerRoll = this.leaders.commander.getSkill("military") + randint(0, this.leaders.commander.getSkill("luck")) ;
                        if (attackerRoll > defenderRoll) {
                            connection.control -= 10;
                            if (connection.control <= 0) {
                                connection.owner.abandonSystem(connection);
                                this.claimSystem(connection);
                            }
                        } else {
                            connection.control += connection.control < 100 ? 10 : 0;
                        }
                    }
                }
            }
        }


        // this.tickPositions();
    }

    tickPositions() {

        for (let character of this.characters) {
            character.tick();
        }

        for (let key in this.positions) {
            let position = this.positions[key];
            position.tick();
        }
    }

}