import LocalMapView from "../views/LocalMap";
import SettingsView from "../views/Settings";
import SystemMapView from "../views/SystemMap";

var ui = {
    keys: {},
    views: {},
    activeViews: [],
    debug: false,
    get isViewOpen() {
        if (this.activeViews.length > 0) return true;
        return false;
    },
    get currentView() {
        return ui.activeViews[ui.activeViews.length - 1] || false;
    }
}

ui.views = {
    SystemMap: new SystemMapView(),
    LocalMap: new LocalMapView(),
    Settings: new SettingsView()
};

export { ui }