// Define parameters for Celestial Objects

// Celestial Primaries can have jets true/false or noGlow true/false.

export const definitions = {
    celestials: {
        planets: [
            {
                name: "Terrestrial",
                designator: "TR",
                color: { r: 45, g: 105, b: 255 },
                radius: { min: 0.33, max: 3 },
                tempLimits: { min: 120, max: 350 },
                rarity: 50,
                habitable: true,
                ringsAllowed: true,
                naturalSatellitesAllowed: true,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 1, max: 3},
                    gas: {min: 0, max: 1},
                    basic: {min: 2, max: 4},
                    exotic: {min: 0, max: 0}
                }
            },
            {
                name: "Gas Giant",
                designator: "GG",
                color: { r: 76, g: 204, b: 255 },
                radius: { min: 7, max: 30 },
                tempLimits: { min: 120, max: 500 },
                rarity: 30,
                habitable: false,
                ringsAllowed: true,
                naturalSatellitesAllowed: true,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 0, max: 0},
                    gas: {min: 2, max: 5},
                    basic: {min: 0, max: 0},
                    exotic: {min: 0, max: 0.5}
                }
            },
            {
                name: "Ice Giant",
                designator: "IG",
                color: { r: 247, g: 252, b: 255 },
                radius: { min: 2.5, max: 6 },
                tempLimits: { min: 30, max: 90 },
                rarity: 20,
                habitable: false,
                ringsAllowed: true,
                naturalSatellitesAllowed: true,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 0.5, max: 1.5},
                    gas: {min: 1, max: 3},
                    basic: {min: 0, max: 0},
                    exotic: {min: 0, max: 1}
                },
                description: "Composed mainly of heavier elements like oxygen, nitrogen, and carbon. These bodies have deep atmospheres that often transition to liquid far below. Uranus and Neptune in the Sol System are examples of Ice Giants."
            },
            {
                name: "Metal Rich",
                designator: "MR",
                color: { r: 255, g: 247, b: 163 },
                radius: { min: 0.25, max: 2 },
                tempLimits: { min: 0, max: 100000 },
                rarity: 20,
                habitable: true,
                ringsAllowed: true,
                naturalSatellitesAllowed: true,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 3, max: 7},
                    gas: {min: 0, max: 0},
                    basic: {min: 1, max: 2},
                    exotic: {min: 0, max: 0.3}
                }
            },
            {
                name: "Frozen",
                designator: "FR",
                color: { r: 255, g: 240, b: 84 },
                radius: { min: 0.75, max: 2 },
                rarity: 30,
                tempLimits: { min: 0, max: 270 },
                habitable: true,
                ringsAllowed: true,
                naturalSatellitesAllowed: true,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 0.5, max: 1.5},
                    gas: {min: 0.25, max: 1},
                    basic: {min: 0.5, max: 1.5},
                    exotic: {min: 1, max: 2}
                },
                description: "Globally covered in solid volatile compounds like water or methane. Somtimes capable of supporting life on the surface or in vast oceans under the ice due to geothermal heating or internal friction if part of a multibody system. "

            },
            {
                name: "Ocean",
                designator: "ON",
                color: { r: 255, g: 240, b: 84 },
                radius: { min: 1, max: 3 },
                rarity: 30,
                tempLimits: { min: 100, max: 375 },
                habitable: true,
                ringsAllowed: true,
                naturalSatellitesAllowed: true,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 0, max: 0},
                    gas: {min: 0.5, max: 1.5},
                    basic: {min: 0.5, max: 1.5},
                    exotic: {min: 0, max: 1}
                }
            },
            {
                name: "Molten",
                designator: "MT",
                color: { r: 255, g: 240, b: 84 },
                radius: { min: 0.5, max: 2 },
                tempLimits: { min: 870, max: 3100 },
                rarity: 10,
                habitable: false,
                ringsAllowed: true,
                naturalSatellitesAllowed: true,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 1, max: 3},
                    gas: {min: 1, max: 2},
                    basic: {min: 0, max: 0},
                    exotic: {min: 1, max: 3}
                }
            }
        ],

        asteroids: [
            {
                name: "Rocky Asteroid",
                designator: "RA",
                type: "asteroid",
                color: { r: 255, g: 240, b: 84 },
                radius: { min: 0.01, max: 0.09 },
                tempLimits: { min: 0, max: 100000 },
                rarity: 50,
                habitable: false,
                ringsAllowed: false,
                naturalSatellitesAllowed: false,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 0.5, max: 1.5},
                    gas: {min: 0, max: 0},
                    basic: {min: 0, max: 0},
                    exotic: {min: 0, max: 0.5}
                }
            },
            {
                name: "Metallic Asteroid",
                designator: "MA",
                type: "asteroid",
                color: { r: 255, g: 240, b: 84 },
                radius: { min: 0.008, max: 0.08 },
                tempLimits: { min: 0, max: 100000 },
                rarity: 5,
                habitable: false,
                ringsAllowed: false,
                naturalSatellitesAllowed: false,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 1, max: 3},
                    gas: {min: 0, max: 0},
                    basic: {min: 0, max: 0},
                    exotic: {min: 0, max: 0}
                }
            }
        ],

        planetoids: [
            {
                name: "Dwarf Planet",
                designator: "DP",
                color: { r: 255, g: 240, b: 84 },
                radius: { min: 0.075, max: 0.2 },
                tempLimits: { min: 0, max: 100000 },
                rarity: 30,
                habitable: false,
                ringsAllowed: true,
                naturalSatellitesAllowed: true,
                artificialSatellitesAllowed: true,
                resources: {
                    mineral: { min: 0.25, max: 1},
                    gas: {min: 0, max: 0},
                    basic: {min: 0, max: 0},
                    exotic: {min: 0, max: 0.25}
                }
            }
        ],

        mainSequenceStars: [
            {
                name: "O",
                temp: { min: 30000, max: 60000 },
                color: { r: 45, g: 105, b: 255 },
                mass: { min: 16, max: 50 },
                radius: { min: 6.6, max: 10 },
                luminosity: { min: 30000, max: 100000 },
                rarity: 5,
                glow: true,
                jets: false
            },
            {
                name: "B",
                temp: { min: 10000, max: 30000 },
                color: { r: 76, g: 204, b: 255 },
                mass: { min: 2.1, max: 16 },
                radius: { min: 1.8, max: 6.6 },
                luminosity: { min: 25000, max: 30000 },
                rarity: 10,
                glow: true,
                jets: false
            },
            {
                name: "A",
                temp: { min: 7500, max: 10000 },
                color: { r: 160, g: 180, b: 255 },
                mass: { min: 1.4, max: 2.1 },
                radius: { min: 1.4, max: 1.8 },
                luminosity: { min: 5, max: 25 },
                rarity: 20,
                glow: true,
                jets: false
            },
            {
                name: "F",
                temp: { min: 6000, max: 7500 },
                color: { r: 255, g: 247, b: 163 },
                mass: { min: 1.04, max: 1.4 },
                radius: { min: 1.15, max: 1.4 },
                luminosity: { min: 1.5, max: 5 },
                rarity: 30,
                glow: true,
                jets: false
            },
            {
                name: "G",
                temp: { min: 5200, max: 6000 },
                color: { r: 255, g: 240, b: 84 },
                mass: { min: 0.8, max: 1.04 },
                radius: { min: 0.96, max: 1.5 },
                luminosity: { min: 0.6, max: 1.5 },
                rarity: 40,
                glow: true,
                jets: false
            },
            {
                name: "K",
                temp: { min: 3700, max: 2500 },
                color: { r: 255, g: 166, b: 58 },
                mass: { min: 0.45, max: 0.8 },
                radius: { min: 0.7, max: 0.96 },
                luminosity: { min: 0.08, max: 0.6 },
                rarity: 50,
                glow: true,
                jets: false
            },
            {
                name: "M",
                temp: { min: 2400, max: 3700 },
                color: { r: 255, g: 89, b: 63 },
                mass: { min: 0.08, max: 0.45 },
                radius: { min: 0.2, max: 0.7 },
                luminosity: { min: 0.01, max: 0.08 },
                rarity: 60,
                glow: true,
                jets: false
            }
        ],

        blackHoles: [
            {
                name: "Black Hole",
                designator: "BH",
                temp: { min: 0, max: 0 },
                color: { r: 255, g: 255, b: 255},
                fill: { r: 0, g: 0, b: 0 },
                mass: { min: 5, max: 1000 },
                radius: { min: 0.05, max: 15 },
                rarity: 3,
                planetLikelihoodModifier: 0.25,
                glow: false,
                jets: false
            }
        ],

        exoticStars: [
            {
                name: "Neutron",
                designator: "NU",
                temp: { min: 0, max: 0 },
                color: { r: 100, g: 50, b: 255},
                mass: { min: 1, max: 2 },
                radius: { min: 0.00001, max: 0.00005 },
                rarity: 3,
                planetLikelihoodModifier: 0.2,
                glow: true,
                jets: true
            },
            {
                name: "White Dwarf",
                designator: "WD",
                temp: { min: 0, max: 0 },
                color: { r: 255, g: 255, b: 255},
                mass: { min: 0.2, max: 1.3 },
                radius: { min: 0.008, max: 0.02},
                luminosity: { min: 0.0001, max: 0.0005 },
                rarity: 3,
                planetLikelihoodModifier: 0.2,
                glow: false,
                jets: false
            }
        ],
        novas: []
    },
    traits: {},
    resources: {
        mineral: {
            name: "Minerals",
            value: 100,
            volume: 1000000,
            productionRate: 10,
            regenerationRate: 2,
            regenerationAllowed: false,
            depletionAllowed: false
        },
        gas: {
            name: "Gasses",
            value: 100,
            volume: 1000000,
            productionRate: 10,
            regenerationRate: 2,
            regenerationAllowed: false,
            depletionAllowed: false
        },
        basic: {
            name: "Basic Materials",
            value: 100,
            volume: 1000000,
            productionRate: 10,
            regenerationRate: 2,
            regenerationAllowed: false,
            depletionAllowed: false
        },
        exotic: {
            name: "Exotic Matter",
            value: 10,
            volume: 1000000,
            productionRate: 10,
            regenerationRate: 0.2,
            regenerationAllowed: false,
            depletionAllowed: false
        }
    }
}
