import Component from "../../components/Component";
import { ui } from "../../js/ui";
import viewTemplate from "./template.hbs";

export default class View extends Component {
    constructor(componentTemplate, openTriggers)  {
        
        super()

        this.createElement({parent: false, template: viewTemplate})

        this.viewElement = this.element;
        this.element.querySelector(".view__content").innerHTML = componentTemplate();
        this.closeButton = this.element.querySelector(".view__close");
        this.isOpen = false;
        this.allowCloseKey = true;
        this.bindViewEvents(openTriggers);
    }
  
    open() {
        this.element.classList.add("view--opening");
        document.body.appendChild(this.element); 
        this.isOpen = true;
        ui.activeViews.push(this);
        console.log(ui.activeViews);
        console.log(ui.currentView)
        window.setTimeout(() => {
            this.element.classList.remove("view--opening");
        },150)
    }
    
    close() {
        this.element.classList.add("view--closing");
        this.isOpen = false;
        ui.activeViews.pop();
        window.setTimeout(() => {
            document.body.removeChild(this.element);
            this.element.classList.remove("view--opening");
            this.element.classList.remove("view--closing");
        }, 150);
        
    }
    
    bindViewEvents(openTriggers) {

        this.closeButton.addEventListener("click", e => {
            this.close();
        })            

        if (openTriggers) {
            for (let selector of openTriggers) {
                let triggerElements = document.querySelectorAll(selector);
                for (let trigger of triggerElements) {
                    trigger.addEventListener("click", e => {
                        this.open();
                    });
                }
            }
        }
        
    }
    
}