import config from "../../js/ConfigManager";
import {randint} from "../../js/utilities";
import template from "./template.hbs";
import Component from "../Component";
import tracklist from "./tracklist";

export default class MusicPlayer extends Component {
    
    constructor(parent) {
        
        super();

        this.createElement({parent, template})
        
        this.player = this.element.querySelector("audio")
        this.songTitle = this.element.querySelector(".music-player__title");
        this.playButton = this.element.querySelector(".music-player__play");
        this.pauseButton = this.element.querySelector(".music-player__pause");
        this.nextButton = this.element.querySelector(".music-player__next");
        this.randomButton = this.element.querySelector(".music-player__random");
        this.volumeButton = this.element.querySelector(".music-player__volume");
        this.seekBar = this.element.querySelector(".music-player__seek");
        this.progressBar = this.element.querySelector(".music-player__progress");
        
        this.tracklist = tracklist;
        this.trackIndex = randint(0, this.tracklist.length-1);
        this.random = false;

        this.bindEvents();

        window.setTimeout(() => {
            this.nextTrack();
        }, 0)

    }
    
    nextTrack() {
        if (this.random) {
            this.trackIndex = randint(0, this.tracklist.length-1);
        } else {
            this.trackIndex = this.trackIndex == this.tracklist.length - 1 ? 0 : this.trackIndex + 1;        
        }

        let track = this.tracklist[this.trackIndex];
        this.songTitle.innerHTML = track.title
        this.player.src = track.path;
        
        this.startTrack();
    }
    
    bindEvents() {
        
        this.player.addEventListener("canplaythrough", e => {
            
            
            let musicPromise = this.player.play();
            
            if (musicPromise !== undefined) {
                musicPromise.then( e => {
                    this.element.classList.add("music-player--playing");
                }).catch(error => {
                    
                });
            }
            
            
            
            
        })
        
        this.player.addEventListener("ended", e => {
            this.nextTrack();
        })
        
        this.player.addEventListener("play", e => {
            this.element.classList.add("music-player--playing");
        });

        this.player.addEventListener("pause", e => {
            this.element.classList.remove("music-player--playing");
        });
        
        this.player.addEventListener("timeupdate", e => {
            this.progressBar.style.transform = `translateX(${-100 + Math.ceil((this.player.currentTime / this.player.duration) * 100)}px)`;
        });
        
        this.playButton.addEventListener("click", e => {
            this.player.play();
            // this.element.classList.add("music-player--playing");
        });
        
        this.pauseButton.addEventListener("click", e => {
            this.player.pause();    
        });
        
        this.nextButton.addEventListener("click", e => {
            this.nextTrack();
        });

        this.randomButton.addEventListener("click", e => {
            this.random = !this.random;
            if (this.random) {
                this.element.classList.add("music-player--random");
            } else {
                this.element.classList.remove("music-player--random")
            }
            
        });

        this.seekBar.addEventListener("click", e => {
            this.seekTo(e.offsetX / this.seekBar.clientWidth)
        });
        
    }
    
    startTrack() {
        this.player.load();        
    }

    seekTo(percent) {
        this.player.currentTime = percent * this.player.duration;
    }
    
    
    
}

