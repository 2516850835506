
import MusicPlayer from "../components/MusicPlayer";
import GalaxyMap from "../components/GalaxyMap";
import MainPanel from "../components/MainPanel";
import PerformanceMonitor from "../components/PerformanceMonitor";

import config from "./ConfigManager";
// import galaxy from "./universe/Galaxy";
import loader from "../components/Loader";
import { gameData } from "./gameData";
import { Keys, ui, Views} from "./ui";
import Galaxy from "./universe/Galaxy";
import { rng } from "./utilities";

const targetTPS = 100;
const targetTPU = 1; // Ticks Per Update, render the dynamic map every X ticks
let tickCount = 0;

export default class ExAstris {

    constructor() {var savedData = localStorage.getItem("gameData");


        this.element = document.querySelector(".app");
        this.musicPlayer = new MusicPlayer(this.element);
        this.mainPanel = new MainPanel(document.body);
        this.map = new GalaxyMap(this.element);
        this.perfMon = new PerformanceMonitor(this.element);

        for (let i=0; i<10; i++) {
            console.log( rng() );
        }

        this.galaxy = new Galaxy();

        window.addEventListener("galaxyloaded", (e) => {

            for (let civ of gameData.civilizations) {
                if (civ.homeworld.system.connections[0].owner == null) {
                    civ.homeworld.system.connections[0].owner = civ;
                    civ.homeworld.system.connections[0].name = civ.language.translate("Shelter")
                    civ.systems.push(civ.homeworld.system.connections[0]);
                }
            }

            this.bindGlobalEvents();
            this.map.start();
            this.map.renderDynamic();
            // this.systemsView.init();
            loader.close();
            console.log(gameData)
            this.tick();
            // this.render();
        });

    }

    bindGlobalEvents() {
        
        window.addEventListener("keydown", (e) => {
            ui.keys[e.code] = true;
        });

        window.addEventListener("keyup", (e) => {
            ui.keys[e.code] = false;

            if (e.code == "Backquote") {
                ui.debug = !ui.debug;
                this.perfMon.toggle();
            }

        });
    }

    render() {



        window.requestAnimationFrame(() => {this.render()})
    }

    tick() {
        
        const tickStartTime = performance.now();
        
        for (let star of gameData.stars) star.tick();
        for (let orbital of gameData.orbitals) orbital.tick();
        for (let character of gameData.characters) character.tick();
        if (ui.keys['Enter'] || ui.keys['NumpadEnter']) { for (let civilization of gameData.civilizations) civilization.tick(); }
        for (let species of gameData.species) species.tick();
        
        const tickEndTime = performance.now();

        // const renderStartTime = performance.now();
        // this.map.renderDynamic();
        // const renderEndTime = performance.now();
        // this.map.updateCivOverview();

        this.tickTimer = setTimeout(() => {
            this.tick();
        }, 1000 / targetTPS)


        const tickDuration = tickEndTime - tickStartTime;
        const tps = Math.min(targetTPS, 1000 / tickDuration)

        gameData.performance.logic.tps = Math.floor(tps);
        gameData.performance.logic.time = tickDuration.toFixed(2);
        
        tickCount++;

    }


}
