<div class="performance-monitor__section">
    <p>Logic</p>
    <div class="performance-monitor__stat"><span>TPS: </span><span>{{performance.logic.tps}}</span></div>
    <div class="performance-monitor__stat"><span>Tick Time: </span><span>{{performance.logic.time}} ms</span></div>
</div>

<div class="performance-monitor__section">
    <p>Render</p>
    <div class="performance-monitor__stat"><span>Galaxy: </span><span>{{performance.render.galaxyMap}} ms</span></div>
    <div class="performance-monitor__stat"><span>System: </span><span>{{performance.render.systemMap}} ms</span></div>
    <div class="performance-monitor__stat"><span>Local: </span><span>{{performance.render.localMap}} ms</span></div>
</div>

<div class="performance-monitor__section">
    <p>Game</p>
    <div class="performance-monitor__stat"><span>Systems: </span><span>{{systems}}</span></div>
    <div class="performance-monitor__stat"><span>Orbitals: </span><span>{{orbitals}}</span></div>
    <div class="performance-monitor__stat"><span>Species: </span><span>{{species}}</span></div>
    <div class="performance-monitor__stat"><span>Civilizations: </span><span>{{civilizations}}</span></div>
    <div class="performance-monitor__stat"><span>Characters: </span><span>{{characters}}</span></div>
</div>