import View from "../View";
import config from "../../js/ConfigManager";
import template from "./template.hbs";

import { gameData } from "../../js/gameData";
import SystemMap from "../../components/SystemMap";


export default class SystemMapView extends View {

    constructor() {
        super(template);
        this.viewElement.style.background = "#050505";
        this.map = new SystemMap(this.element.querySelector(".system-map-view__map"));
    }

    viewSystem(system) {
        // this.element.querySelector(".system-map__system-name").innerText = system.name || system.catalogName;
        this.map.render(system);
        this.open();
    }
   
}
