import defaultTemplate from "./template.hbs";


export default class Component {
    
    constructor() {
        
        // let wrapper = document.createElement("div");
        // wrapper.innerHTML = template();
        // this.element = wrapper.firstChild;
    
        // window.addEventListener("tick", () => { this.tick() })

        this.blockKeys = false;

        
    }

    createElement({
        parent = false,
        template = defaultTemplate,
        scaffold = "div"
    } = {}) {

        scaffold = document.createElement(scaffold);
        scaffold.innerHTML = template();
        this.element = scaffold.firstElementChild;


        if (parent) {
            if (typeof parent == "string") {
                parent = document.querySelector(parent);
            }
            parent.appendChild(this.element);
        }


    }
    
    tick() {
        return true;
    }
    
}