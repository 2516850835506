import {randint, randfloat} from "../utilities";
import config from "../ConfigManager";
import { gameData } from "../gameData";
import { definitions } from "../Definitions";

const sbConstant = 5.670374419 * (10 ** -8);

const solarMetrics = {
    mass: { value: 1.989 * (10**30), units: "kg" },
    radius: { value: 696340, units: "km"},
    temp: {value: 5778, units: "K" },
    luminosity: { value: (4 * Math.PI * (696340 ** 2) * sbConstant * (5778 ** 4)), units: "W"}
}

function selectClass() {
   
    let options = [];

    if (config.systems.centralBodies.types.mainSequenceStars) options = options.concat(definitions.celestials.mainSequenceStars);
    if (config.systems.centralBodies.types.blackHoles) options = options.concat(definitions.celestials.blackHoles);
    if (config.systems.centralBodies.types.exoticStars) options = options.concat(definitions.celestials.exoticStars);
    if (config.systems.centralBodies.types.novas) options = options.concat(definitions.celestials.novas);
    
    let starClass = false;

    while (!starClass) {
        let choice = options[randint(0, options.length-1)];
        starClass = (randint(0,100) < choice.rarity) ? choice : false;
    }

    return Object.assign({}, starClass);
}

export default class Star {
    
    constructor(system) {
        this.system = system;
        let starClass = selectClass();
        
        this.class = starClass.name;
        this.designator = (starClass.designator) ? starClass.designator : starClass.name;
        this.temperature = randint(starClass.temp.min, starClass.temp.max);

        this.color = starClass.color || { r: 255, g: 255, b: 255 };
        this.fill = starClass.fill || false;
        this.glow = starClass.glow || false;
        this.jets = starClass.jets || false;

        let massFactor = randfloat(starClass.mass.min, starClass.mass.max); 
        this.mass = { factor: massFactor.toPrecision(2), value: (massFactor * solarMetrics.mass.value).toPrecision(4)};

        let radiusFactor = randfloat(starClass.radius.min, starClass.radius.max)       
        this.radius = { factor: radiusFactor.toPrecision(2), value: (radiusFactor * solarMetrics.radius.value).toPrecision(4)};   

        let luminosity = (4 * Math.PI * (this.radius.value ** 2) * sbConstant * (this.temperature ** 4)); 
        this.luminosity = {actual: luminosity, exponential: luminosity.toExponential(2), solar: (luminosity / solarMetrics.luminosity.value).toFixed(2) };
        
        // this.habitableZone = { min: Math.sqrt((luminosity / 1.1)), max: Math.sqrt((luminosity / 0.53)) }
        
    
        this.updates = {
            self: true,
            data: true
        }

        gameData.stars.push(this);

    }

    tick() {
        
    }
   

    
}
