import { gameData } from "../gameData";
import { randint } from "../utilities";

export default class Character {
    constructor(civilization) {

        this.civilization = civilization;
        this.name = this.civilization.language.createWord() + " " + this.civilization.language.createWord();
        this.skills = {
            economics: randint(1,10),
            politics: randint(1,10),
            science: randint(1,10),
            military: randint(1,10),
            charisma: randint(1,10),
            integrity: randint(1,10),
            luck: randint(1,10)
        }

        this.employed = false;

        gameData.characters.push(this)

    }

    getSkill(skill) {
        return ((this.civilization.species.skills[skill] * 2) + this.skills[skill]);
    }

    update() {
        
    }

    tick() {

    }

}