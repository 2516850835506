import System from "./System";
import config from '../ConfigManager';
import loader from "../../components/Loader";

// import Civilization from "./civilization/Civilization"
export default class Galaxy {

    constructor() {

        this.systems = [];
        this.init();

        window.addEventListener("tick", () => { this.tick() });
        // this.populateSystems();        
    }

    init() {

        this.totalSteps = (config.map.systems * 3) + config.map.spacingPasses;
        this.completedSteps = 0;

        this.createSystems();

        window.addEventListener("systemscreated", () => { this.relaxSystems() });
        window.addEventListener("systemsrelaxed", () => { this.populateSystems() });
        window.addEventListener("systemspopulated", () => { this.connectSystems() });
        window.addEventListener("systemsconnected", () => { window.dispatchEvent(new Event("galaxyloaded")); });

    }


    createSystems() {
        this.systems.push(new System(this.systems.length + 1));
        this.completedSteps++;
        // loader.setMessage('Triggering Big Bang')
        loader.setMessage('Expanding Universe');
        loader.setProgress(Math.floor((this.completedSteps / this.totalSteps) * 100));
        if (this.systems.length < config.map.systems) {
            window.setTimeout(() => { this.createSystems() }, 0)
        } else {
            window.dispatchEvent(new Event("systemscreated"));
        }
    }

    relaxSystems(i = 0) {
        if (i < config.map.spacingPasses) {
            this.completedSteps++;
            // loader.setMessage('Enforcing Hubble\'s Law')
            loader.setMessage('Forming Galaxy');
            loader.setProgress(Math.floor((this.completedSteps / this.totalSteps) * 100));
            for (let system of this.systems) {
                system.relaxSpacing(this.systems);
            }
            window.setTimeout(() => { this.relaxSystems(i + 1) }, 0)
        } else {
            window.dispatchEvent(new Event("systemsrelaxed"));
        }
    }

    connectSystems(i = 0) {
        if (i < this.systems.length) {
            this.completedSteps++;
            // loader.setMessage('Generating Einstein–Rosen Bridges');
            loader.setMessage('Mapping Starlanes');
            loader.setProgress(Math.floor((this.completedSteps / this.totalSteps) * 100));
            let system = this.systems[i];
            system.connect(this.systems);
            window.setTimeout(() => { this.connectSystems(i + 1) }, 0)
        } else {
            window.dispatchEvent(new Event("systemsconnected"));
        }
    }




    populateSystems(i=0) {
        
        if (i < this.systems.length) {
            this.completedSteps++;
            // loader.setMessage('Generating Einstein–Rosen Bridges');
            loader.setMessage('Creating Life');
            loader.setProgress(Math.floor((this.completedSteps / this.totalSteps) * 100));
            let system = this.systems[i];
            system.populate(this.systems);
            window.setTimeout(() => { this.populateSystems(i + 1) }, 0)
        } else {
            window.dispatchEvent(new Event("systemspopulated"));
        }
    }

    tick() {

        for (let system of this.systems) {
            system.randomize()
        }
    }

}



// let galaxy = new Galaxy();

// export default galaxy;
