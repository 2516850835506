{{!-- {{#if owner}}
<div class="info-panel__owner">
    Governed by the <span>{{owner.species.name}}</span>
</div>
{{/if}}

{{#if nativeSpecies}}
<div class="info-panel__home">
    Home of the
    <div class="info-panel__home-name">
        {{nativeSpecies.name}}
    </div>
</div>
{{/if}} --}}

{{!--
<div class="info-panel__header">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 470"
        xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 470 470">
        <g>
            <path
                d="m168.757,205.591l-7.525-2.445c-3.935-1.28-8.171,0.875-9.45,4.815-1.28,3.939 0.876,8.17 4.815,9.45l7.507,2.439c-1.044,4.889-1.604,9.954-1.604,15.149s0.561,10.261 1.604,15.149l-7.507,2.439c-3.939,1.28-6.096,5.511-4.815,9.45 1.029,3.169 3.97,5.185 7.131,5.185 0.769,0 1.55-0.119 2.319-0.369l7.525-2.445c4.179,9.375 10.278,17.71 17.814,24.488l-4.661,6.416c-2.435,3.351-1.692,8.042 1.659,10.476 1.331,0.967 2.873,1.433 4.402,1.433 2.318,0 4.606-1.072 6.073-3.092l4.657-6.411c8.637,5.018 18.389,8.319 28.798,9.394v7.888c0,4.142 3.357,7.5 7.5,7.5s7.5-3.358 7.5-7.5v-7.887c10.409-1.075 20.161-4.376 28.798-9.394l4.657,6.411c1.467,2.02 3.754,3.092 6.073,3.092 1.528,0 3.071-0.466 4.402-1.433 3.352-2.435 4.094-7.125 1.659-10.476l-4.661-6.416c7.536-6.778 13.635-15.113 17.814-24.488l7.524,2.445c0.77,0.25 1.551,0.369 2.319,0.369 3.161,0 6.102-2.015 7.131-5.184 1.28-3.939-0.876-8.171-4.815-9.451l-7.506-2.439c1.044-4.889 1.604-9.954 1.604-15.15s-0.561-10.261-1.604-15.15l7.506-2.439c3.939-1.28 6.096-5.511 4.815-9.451-1.279-3.94-5.514-6.095-9.45-4.815l-7.524,2.445c-4.179-9.375-10.278-17.71-17.814-24.488l4.661-6.416c2.435-3.351 1.692-8.042-1.659-10.476-3.348-2.434-8.041-1.693-10.476,1.66l-4.657,6.411c-8.637-5.018-18.389-8.319-28.798-9.394v-7.886c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v7.887c-10.409,1.075-20.161,4.376-28.798,9.394l-4.657-6.411c-2.434-3.351-7.124-4.093-10.476-1.66-3.352,2.435-4.094,7.125-1.659,10.476l4.661,6.416c-7.534,6.779-13.633,15.114-17.812,24.489zm66.243-28.091c31.706,0 57.5,25.794 57.5,57.5s-25.794,57.5-57.5,57.5-57.5-25.794-57.5-57.5 25.794-57.5 57.5-57.5z" />
            <path
                d="M401.17,68.83C356.784,24.444,297.771,0,235,0S113.216,24.444,68.83,68.83S0,172.229,0,235s24.444,121.784,68.83,166.17   S172.229,470,235,470c40.195,0,79.866-10.326,114.725-29.86c3.614-2.025,4.901-6.596,2.877-10.209s-6.595-4.902-10.21-2.876   C309.769,445.336,272.633,455,235,455c-121.309,0-220-98.691-220-220S113.691,15,235,15s220,98.691,220,220   c0,37.63-9.669,74.769-27.961,107.403c-2.025,3.613-0.738,8.184,2.875,10.209c3.611,2.023,8.184,0.739,10.209-2.875   C459.669,314.868,470,275.192,470,235C470,172.229,445.556,113.216,401.17,68.83z" />
            <path
                d="m395.867,355.867c-22.056,0-40,17.944-40,40s17.944,40 40,40 40-17.944 40-40-17.944-40-40-40zm0,65c-13.785,0-25-11.215-25-25s11.215-25 25-25 25,11.215 25,25-11.215,25-25,25z" />
            <path
                d="m120.979,349.021c31.437,31.436 72.729,47.154 114.021,47.154s82.585-15.718 114.021-47.154c62.871-62.872 62.871-165.17 0-228.042-45.445-45.445-113.45-59.574-173.251-36-0.956,0.377-1.827,0.949-2.555,1.676-2.929,2.929-2.928,7.677 0.001,10.605 2.221,2.22 5.486,2.757 8.215,1.61 54.205-21.297 115.805-8.464 156.982,32.714 57.023,57.023 57.023,149.806 0,206.829-28.515,28.515-65.958,42.771-103.414,42.767-37.447-0.003-74.906-14.259-103.414-42.767-41.217-41.217-54.03-102.898-32.645-157.141 1.52-3.854-0.373-8.209-4.227-9.729-3.855-1.519-8.21,0.374-9.729,4.227-23.575,59.804-9.447,127.809 35.995,173.251z" />
            <path
                d="m126.282,86.282c-10.685,0-20.729,4.161-28.283,11.716-7.556,7.555-11.717,17.6-11.717,28.284s4.161,20.729 11.716,28.284c7.555,7.555 17.6,11.716 28.284,11.716s20.729-4.161 28.285-11.716c15.595-15.596 15.595-40.972-0.001-56.568-7.554-7.555-17.599-11.716-28.284-11.716zm17.678,57.678c-4.722,4.722-11,7.323-17.678,7.323s-12.955-2.601-17.678-7.323c-4.722-4.722-7.322-11-7.322-17.678s2.601-12.956 7.323-17.678c4.722-4.722 10.999-7.322 17.677-7.322s12.956,2.601 17.678,7.322c9.747,9.748 9.747,25.608 0,35.356z" />
        </g>
    </svg>
    {{#if system.name}}
    {{system.name}}
    {{else}}
    {{system.catalogName}}
    {{/if}}
</div>


<table class="info-panel__table">
    <tr>
        <th>Major Bodies</th>
        <td>{{system.planets.length}}</td>
    </tr>
    <tr>
        <th>Starlanes</th>
        <td>{{system.connections.length}}</td>
    </tr>
</table> --}}

<h1>{{system.name}}</h1>

<div class="info-panel__section">
   
    <table class="info-panel__table">
        <tr>
            <th>Class</th>
            <td>{{system.star.class}}</td>
        </tr>
        <tr>
            <th>Radius</th>
            <td>{{system.star.radius.value}} km</td>
        </tr>
        <tr>
            <th>Mass</th>
            <td>{{system.star.mass.value}} kg</td>
        </tr>
        <tr>
            <th>Luminosity</th>
            <td>{{system.star.luminosity.exponential}} W</td>
        </tr>
        <tr>
            <th>Temperature</th>
            <td>{{system.star.temperature}} K</td>
        </tr>
    </table>
</div>

<div class="info-panel__section">
    {{#if object}}
 
    <h2>{{#if object.name}}{{object.name}}{{else}}Unclaimed{{/if}}</h2>
    
    <table class="info-panel__table">
        <tr>
            <th>Class</th>
            <td>{{object.class.name}}</td>
        </tr>
        <tr>
            <th>Radius</th>
            <td>{{object.radius.value}} km</td>
        </tr>
        <tr>
            <th>Axial Tilt</th>
            <td>{{object.axialTilt}}&deg;</td>
        </tr>
        <tr>
            <th>Temperature</th>
            <td>{{object.temperature}} K</td>
        </tr>
        <tr>
            <th>Populated</th>
            <td>{{#if object.populated}}Yes{{else}}No{{/if}}</td>
        </tr>
    </table>

    <h3>Resources</h3>

    <table class="info-panel__table">
        {{#each object.resources}}
           {{#if value}}<tr><th>{{name}}</th><td>{{value}}</td></tr>{{/if}}
        {{/each}}
    </table>

    {{#if object.class.description}} 
        <hr />
        <blockquote>{{object.class.description}}</blockquote>
    {{/if}}

    {{/if}}

</div>