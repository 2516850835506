import { shuffle, randint, distance, distToLine, randfloat, rng } from "../utilities";
import config from '../ConfigManager';

import Star from "./Star";
import Orbital from "./Orbital";
import Civilization from "../civilization/Civilization";
import { gameData } from "../gameData";
// import SystemStory from "../SystemStory";

const catalogNames = [
    "HAC-",
    "TINSL ",
    "Q17-",
    "TERRA ",
    "DeepStar ",
    "NorStar ",
    "CASBR-",
    "JRC-",
    "CA-"
]


export default class System {

    constructor(index) {


        let radius = config.map.width < config.map.height ? config.map.width / 2 : config.map.height / 2;
        let margin = radius * 0.2;
        radius *= 0.8;


        let cx = Math.round(config.map.width / 2);
        let cy = Math.round(config.map.height / 2);


        let a = rng() * 2 * Math.PI
        let r = radius * Math.sqrt(rng())
        let x = r * Math.cos(a) + radius + margin;
        let y = r * Math.sin(a) + radius + margin;

        this.connections = [];
        this.position = { x: x, y: y }
        this.name = false;
        this.catalogName = this.createCatalogName();
        this.star = new Star();
        this.star.name = this.name;
        this.orbitals = [];
        this.orbits = [];
        this.habitableCount = 0;
        
        // this.renderParameters = {
        //     radius: 0,
        //     offset: 0,
        //     spacing: 0
        // }

        this.populated = false;
        this.selected = false;
        this.owner = null;
        this.control = 0;
        this.state = {
            war: false
        }

        // this.setRenderParameters();
        this.initOrbits();
        this.createOrbitals();

        // this.name = this.star.designator + "" + index + "-" + this.planets.length + "-" + this.habitableCount;

        gameData.systems.push(this);

    }

    createCatalogName() {
        let catalog = catalogNames[Math.floor(rng() * catalogNames.length)]; 
        let number = Math.floor(this.position.x + this.position.y);
        return `${catalog}${number}`
    }

    // setRenderParameters() {
    //     let maxStar = 15;
    //     let minStar = 0.05;
    //     let maxRadius = 300;
    //     let minRadius = 50;
    //     this.renderParameters.radius = ((this.star.radius.factor - minStar) / (maxStar - minStar)) * (maxRadius - minRadius) + minRadius;
    //     this.renderParameters.offset = Math.floor((this.renderParameters.radius * 2) + 50);
    //     this.renderParameters.spacing = Math.floor((config.map.width / 2 - (this.renderParameters.radius * 2)) / (config.systems.planets.max));
    // }

    getDistanceList(systems, ascending = false) {
        let distances = []
        for (let system of systems) {
            if (system != this && !this.connections.includes(system)) {
                let dist = distance(this.position, system.position);
                distances.push([system, dist])
            }
        }

        distances.sort((a, b) => {
            if (a[1] === b[1]) return 0;
            if (ascending) {
                return (a[1] < b[1]) ? -1 : 1;
            } else {
                return (a[1] > b[1]) ? -1 : 1;
            }
        })

        return distances;
    }

    relaxSpacing(systems) {
        let distanceList = this.getDistanceList(systems, true)
        let tooClose = [];
        for (let distance of distanceList) {
            if (distance[1] < config.map.minSpace) {
                tooClose.push(distance[0].position);
            } else {
                break;
            }
        }

        if (tooClose.length > 0) {

            let avgX = this.position.x;
            let avgY = this.position.y;
            for (let position of tooClose) {
                avgX += position.x;
                avgY += position.y;
            }

            avgX /= tooClose.length + 1;
            avgY /= tooClose.length + 1;

            let deltaX = (avgX > this.position.x) ? -5 : 5;
            let deltaY = (avgY > this.position.y) ? -5 : 5;

            this.position.x += deltaX;
            this.position.y += deltaY;

            if (this.position.x < config.map.width * 0.1) this.position.x = config.map.width * randfloat(0.05, 0.25);
            if (this.position.x > config.map.width * 0.9) this.position.x = config.map.width * randfloat(0.75, 0.95);
            if (this.position.y < config.map.height * 0.1) this.position.y = config.map.height * randfloat(0.05, 0.25);
            if (this.position.y > config.map.height * 0.9) this.position.y = config.map.height * randfloat(0.75, 0.95);
        }
    }

    connect(systems) {
        let distanceList = this.getDistanceList(systems);

        let connections = randint(config.map.minConnections, config.map.maxConnections);

        if (this.connections.length < config.map.maxConnections) {
            for (let i = this.connections.length; i < connections; i++) {
                let system = distanceList.pop()[0];
                if (system.connections.length < config.map.maxConnections) {
                    let tooClose = false;
                    for (let checkSystem of systems) {
                        if (checkSystem != this && checkSystem != system) {
                            let distToConnection = distToLine(checkSystem.position, this.position, system.position);
                            if (distToConnection < config.map.minSpace / 2) {
                                tooClose = true;
                                break;
                            }
                        }
                    }

                    if (!tooClose) {
                        this.connections.push(system);
                        system.connections.push(this);
                    }

                }
            }

            if (this.connections.length == 0) {
                // let system = this.distanceList[0][0];
                for (let system of distanceList) {
                    if (system[0].connections.length > 1) {
                        this.connections.push(system[0]);
                        system[0].connections.push(this);
                        break;
                    }
                }

            }
        }
    }

    createOrbitals() {
        for (let i = 0; i < config.systems.orbitals.maxCount; i++) {
            if (rng() < config.systems.orbitals.likelihood) {
                let orbital = new Orbital(this, i);
                this.habitableCount += (orbital.habitable) ? 1 : 0;
                this.orbitals.push(orbital);
                this.orbits[i].object = orbital;
            }
        }
    }

    populate() {
        for (let orbital of this.orbitals) {
            if (orbital.habitable) {
                if (rng() < config.life.likelihood) {
                    this.inhabited = true;
                    let civilization = new Civilization(orbital);
                    // console.log(civilization)
                }
            }
        }
        // if (this.inhabited) { console.log(this.planets) }
    }

    randomize() {
        this.habitableCount = Math.floor(rng() * 10);

    }

    smooth() {
        this.geography.smooth();
        this.climate.smooth();
    }

    initOrbits() {
        for (let i = 0; i < config.systems.orbitals.maxCount; i++) {
            let temp = this.star.temperature / ((i + 1) * (i+1));
            this.orbits[i] = { temperature: temp, object: null };
        }
    }


    get resources() {
        let resources = {}
        for (let orbital of this.orbitals) {
            for (let key in orbital.resources) {
                if (!resources[key]) { resources[key] = { name: orbital.resources[key].name, value: 0} }
                resources[key].value += orbital.resources[key].value;
            }
        }
        return resources
    }

    get summary() {
        let summary = {
            name: this.name || this.catalogName,
            owner: this.owner ? this.owner.species.name : false,
            nativeSpecies: this.nativeSpecies ? this.nativeSpecies.name : false,
            control: this.control,
            star: this.star,
            orbitals: this.orbitals,
            resources: this.resources
        }
        return summary;
    }

    tick() {

    }



}
