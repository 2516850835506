
import config from "../../js/ConfigManager";
import { distance, getSystemFromCoordinate, isViewOpen, randint } from "../../js/utilities";
import Component from "../Component";
import template from "./template.hbs";
import infoTemplate from "./info.hbs";
import civInfoTemplate from "./civ-info.hbs";

import { gameData } from "../../js/gameData";
import { ui } from '../../js/ui';
import Voronoi from "voronoi";

const VORONOI = new Voronoi();

export default class GalaxyMap extends Component {

    constructor(parent) {

        super()

        this.createElement({ parent, template })

        this.layerSurface = this.element.querySelector(".map__layers");
        this.selector = this.element.querySelector(".map__selector");
        this.infoPanel = this.element.querySelector(".map-info");

        this.layers = {
            voronoi: {},
            ownerConnections: {},
            systemInfo: {},
            static: {},
            staticZoomed: {},
            names: {},
        }

        this.cells = [];

        this.state = {
            scale: 1,
            mouse: false,
            dragDist: 0,
            viewBoundary: {
                margin: 0
            },
            cellSize: false,
            cells: [],
            translate: {
                x: -config.map.width / 2,
                y: -config.map.height / 2
            },
            backgroundTranslate: {
                x: -window.innerWidth / 2,
                y: -window.innerHeight / 2
            },
            keys: {},
            viewing: null,
            drawVoronoi: true
        }

        this.initLayers();
        this.bindMapEvents();
    }


    initLayers() {

        this.layerSurface.style.width = `${config.map.width}px`;
        this.layerSurface.style.height = `${config.map.height}px`;

        for (let x in this.layers) {
            let layer = this.layers[x];
            layer.canvas = document.createElement("canvas");
            layer.canvas.width = config.map.width;
            layer.canvas.height = config.map.height;
            layer.canvas.classList.add("map__layer");
            layer.context = layer.canvas.getContext("2d");
            layer.context.fillStyle = "#fff";
            layer.context.strokeStyle = "#fff";
            this.layerSurface.appendChild(layer.canvas)
        }

        this.layers.staticZoomed.canvas.style.display = "none";
        // this.layers.dynamic.canvas.style.opacity = 0.15;


        this.starLayer = { canvas: document.createElement("canvas") };
        this.starLayer.context = this.starLayer.canvas.getContext("2d");
        this.starLayer.canvas.classList.add("map__star-layer")
        this.starLayer.canvas.width = window.innerWidth * 2;
        this.starLayer.canvas.height = window.innerHeight * 2;
        this.element.prepend(this.starLayer.canvas);

    }

    initVoronoi() {
        let boundingBox = { xl: 0, xr: config.map.width, yt: 0, yb: config.map.height };
        let sites = gameData.systems.map(system => {
            return system.position
        });

        let borderRadius = config.map.width < config.map.height ? config.map.width / 2 : config.map.height / 2;
        borderRadius *= 0.9;

        for (let i=0; i<360; i+=10) {

            var x = Math.cos(i * (Math.PI/180)) * borderRadius + (config.map.width / 2);
            var y = Math.sin(i * (Math.PI/180)) * borderRadius + (config.map.height / 2);

            let borderSite = {x, y}
            console.log(borderSite)
            sites.push(borderSite)
        }


        this.voronoi = VORONOI.compute(sites, boundingBox);

        for (let system of gameData.systems) {
            for (let cell of this.voronoi.cells) {
                if (system.position.x == cell.site.x && system.position.y == cell.site.y) {
                    system.voronoiCell = cell.site.voronoiId;
                    break;
                }
            }
        }

        console.log(this.voronoi)

    }

    getVoronoiCell(id) {
        return this.voronoi.cells.filter(cell => { return cell.site.voronoiId == id })[0]
    }

    assignSystemCell(system) {
        for (let row of this.state.cells) {
            for (let cell of row) {

                if (
                    system.position.x > cell.x1 &&
                    system.position.x < cell.x2 &&
                    system.position.y > cell.y1 &&
                    system.position.y < cell.y2
                ) {
                    cell.systems.push(system);
                    return true;
                }
            }
        }
        return false;
    }

    createRenderCells() {

        let cellSize = config.map.width / config.map.cellScale; // cut map into parts
        this.state.cellSize = cellSize;

        for (let i = 0; i < config.map.cellScale; i++) {
            let row = [];
            for (let j = 0; j < config.map.cellScale; j++) {
                let x1 = j * cellSize;
                let y1 = i * cellSize;
                let x2 = j * cellSize + cellSize;
                let y2 = i * cellSize + cellSize;
                row.push({ x1, x2, y1, y2, systems: [] });
            }
            this.state.cells.push(row)
        }

        for (let system of gameData.systems) {
            let assigned = this.assignSystemCell(system);
            if (!assigned) { console.log("UNASSIGNED SYSTEM", system) }
        }

    }

    renderStars() {
        let canvas = this.starLayer.canvas;
        let context = this.starLayer.context;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "#fff";
        context.shadowBlur = 3;
        for (let i = 0; i < 2000; i++) {
            let x = Math.floor(Math.random() * canvas.width);
            let y = Math.floor(Math.random() * canvas.height);
            let radius = (Math.random() * 1) + 0.1;
            context.beginPath();
            context.arc(x, y, radius, 0, 2 * Math.PI);
            context.fill();
        }

    }


    start() {
        this.updateViewBoundary();
        this.createRenderCells();
        this.renderStars();
        this.renderStatic();
        this.renderStatic(true); // render scaled version
        // this.renderNames();
        this.watchKeys();
        this.updateView();
        this.initVoronoi();
    }

    getSelectedSystem(x, y) {
        let match = gameData.systems.filter(system => {
            return (system.position.x > x - 20 && system.position.x < x + 20) && (system.position.y > y - 20 && system.position.y < y + 20);
        });

        return match.length ? match[0] : false
    }

    showSystem(x, y, openSystemMap = false) {
        let system = this.getSelectedSystem(x, y);
        if (system) {
            if (openSystemMap) { ui.views.SystemMap.viewSystem(system); }
            this.selector.style.left = `${system.position.x}px`;
            this.selector.style.top = `${system.position.y}px`;
            this.selector.style.opacity = 1;
            console.log(system)
            this.infoPanel.innerHTML = infoTemplate(system.summary);
            this.infoPanel.classList.add("info-panel--open")
            this.state.viewing = system;
            console.log(system.voronoiCell)
            console.log(system.position)
        } else {
            this.selector.style.opacity = 0;
            this.updateCivOverview()
            // this.infoPanel.classList.remove("info-panel--open")
            this.state.viewing = false;
        }

    }

    updateCivOverview() {
        this.infoPanel.innerHTML = civInfoTemplate({civilizations: gameData.civilizations});
        this.infoPanel.classList.add("info-panel--open");
    }

    watchKeys() {

        if (!ui.isViewOpen) {
            if (ui.keys.KeyW) { this.state.translate.y += config.map.scrollSpeed; }
            if (ui.keys.KeyS) { this.state.translate.y -= config.map.scrollSpeed; }
            if (ui.keys.KeyA) { this.state.translate.x += config.map.scrollSpeed; }
            if (ui.keys.KeyD) { this.state.translate.x -= config.map.scrollSpeed; }
            if (ui.keys.Space && this.state.viewing) { ui.views.SystemMap.viewSystem(this.state.viewing); }
        }


        window.requestAnimationFrame(() => { this.watchKeys() })
    }

    updateViewBoundary() {

        let scaledWidth = (window.innerWidth - (2 * this.state.viewBoundary.margin)) * (1 / this.state.scale);
        let scaledHeight = (window.innerHeight - (2 * this.state.viewBoundary.margin)) * (1 / this.state.scale);
        this.state.viewBoundary.left = -this.state.translate.x - (scaledWidth / 2);
        this.state.viewBoundary.top = -this.state.translate.y - (scaledHeight / 2);
        this.state.viewBoundary.right = this.state.viewBoundary.left + scaledWidth;
        this.state.viewBoundary.bottom = this.state.viewBoundary.top + scaledHeight;
    }

    updateView() {
        this.layerSurface.style.transform = `translate(${this.state.translate.x}px, ${this.state.translate.y}px) scale(${this.state.scale.toFixed(2)})`;
        this.starLayer.canvas.style.transform = `translate(${this.state.backgroundTranslate.x}px, ${this.state.backgroundTranslate.y}px)`;
        this.updateViewBoundary()

        // SHOULD THIS UPDATE IF NO CHANGES? CONSIDER ADDING UPDATE TEST
        window.requestAnimationFrame(() => this.updateView())
    }

    dragEnd(e) {
        if (e.which == 1 || e.touches != 'undefined') {
            this.state.mouse = false;
            this.state.dragDist = 0;
            this.element.style.cursor = "default";
        }
    }

    dragMove(e) {


        let isTouchEvent = e.type == "touchmove";
        if (isTouchEvent) e = e.touches[0]; // if this was a touch event, use the first touch to get x,y

        // Check if left mouse was down or if touch event
        if ((e.which == 1 || isTouchEvent) && (this.layerSurface.offsetWidth > this.element.offsetWidth || this.layerSurface.offsetHeight > this.element.offsetHeight)) {
            let mouse = { x: e.clientX, y: e.clientY };
            this.element.style.cursor = "move";
            if (this.state.mouse) {
                let boundaries = {
                    xRight: (this.element.offsetWidth / 2) * -0.5,
                    xLeft: (this.layerSurface.offsetWidth - this.element.offsetWidth / 2) * -1.5,
                    yTop: ((this.element.offsetHeight / 2) * -0.5) + 50,
                    yBottom: (this.layerSurface.offsetHeight - this.element.offsetHeight / 2) * -1.5
                }
                let dist = distance(this.state.mouse, mouse);
                let x = (mouse.x - this.state.mouse.x) * config.map.dragSensitivity;
                let y = (mouse.y - this.state.mouse.y) * config.map.dragSensitivity;
                this.state.dragDist += dist;
                this.state.translate.x += x;
                this.state.translate.y += y;

                let starsX = true;
                let starsY = true;

                if (this.state.translate.x > boundaries.xRight) { this.state.translate.x = boundaries.xRight; starsX = false; }
                if (this.state.translate.x < boundaries.xLeft) { this.state.translate.x = boundaries.xLeft; starsX = false; }

                if (this.state.translate.y > boundaries.yTop) { this.state.translate.y = boundaries.yTop; starsY = false; }
                if (this.state.translate.y < boundaries.yBottom) { this.state.translate.y = boundaries.yBottom; starsY = false; }

                if (starsX) this.state.backgroundTranslate.x += x * 0.05;
                if (starsY) this.state.backgroundTranslate.y += y * 0.05;

                // this.element.scrollBy(x, y);
                // this.showSystem(false);
                this.layerSurface.style.transformOrigin = `${this.state.translate.x * -1}px ${this.state.translate.y * -1}px`;

                // this.renderDynamic();

            }
            this.state.mouse = mouse;
        }
    }

    bindMapEvents() {

        window.addEventListener("keyup", e => {
            if (e.code == "Escape" && !ui.isViewOpen) { this.showSystem(null, null) }
            if (e.code == "Space" && !ui.isViewOpen) { this.state.drawVoronoi = !this.state.drawVoronoi; }
        })

        window.addEventListener("mouseleave", e => { this.dragEnd(e) });

        // HANDLE MOUSE EVENTS
        this.element.addEventListener("contextmenu", e => { e.preventDefault(); })
        this.element.addEventListener('dblclick', e => { if (this.state.dragDist < 5) { this.showSystem(e.offsetX, e.offsetY, true); } this.dragEnd(e) });
        this.element.addEventListener("mousemove", e => { if (!ui.isViewOpen) this.dragMove(e); });
        this.element.addEventListener("mouseup", e => {
            if (this.state.dragDist < 5) this.showSystem(e.offsetX, e.offsetY);
            this.dragEnd(e)
        });
        this.element.addEventListener("mouseleave", e => { this.dragEnd(e) });



        // HANDLE TOUCH EVENTS
        this.element.addEventListener("touchmove", e => {
            e.preventDefault();
            if (!ui.isViewOpen) this.dragMove(e);
        })

        this.element.addEventListener("touchend", e => {
            this.dragEnd(e)
        });



        window.addEventListener("mousewheel", e => {
            if (!ui.isViewOpen) {
                this.state.scale += e.deltaY < 0 ? 0.05 : -0.05;
                this.state.scale = Math.min(Math.max(this.state.scale, 0.3), 1);
                if (this.state.scale < 0.7) {
                    this.layers.static.canvas.style.display = "none";
                    this.layers.staticZoomed.canvas.style.display = "block";
                } else {
                    this.layers.static.canvas.style.display = "block";
                    this.layers.staticZoomed.canvas.style.display = "none";
                }
            }
            // this.renderStatic();
        });

    }

    isSystemVisible(system) {


        let x = system.position.x;
        let y = system.position.y;

        return (
            x > (this.state.viewBoundary.left) &&
            x < (this.state.viewBoundary.right) &&
            y > (this.state.viewBoundary.top) &&
            y < (this.state.viewBoundary.bottom)
        );
    }

    renderStatic(scaled = false) {
        let layer = scaled ? this.layers.staticZoomed : this.layers.static;
        let canvas = layer.canvas;
        let context = layer.context;
        let drawRadius = 10;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.textAlign = "center";
        context.font = "15px 'Alegreya Sans SC'";
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.lineWidth = scaled ? 1 : 2;
        context.strokeStyle = "rgba(255,255,255,0.5)";
        context.fillStyle = "#fff";
        context.shadowColor = '#fff';

        let renderedSystems = [];

        for (let system of gameData.systems) {

            context.lineWidth = (scaled) ? 2 : 1;
            context.strokeStyle = "rgba(255,255,255,0.5)";
            context.shadowColor = '#fff';
            context.shadowBlur = 1;

            for (let connectedSystem of system.connections) {
                if (renderedSystems.indexOf(connectedSystem) == -1) {
                    context.beginPath();
                    context.moveTo(system.position.x, system.position.y)
                    context.lineTo(connectedSystem.position.x, connectedSystem.position.y);
                    context.stroke();
                }
            }

            context.beginPath()
            context.shadowBlur = 0;

            let r = system.star.color.r;
            let g = system.star.color.g;
            let b = system.star.color.b;
            let color = "rgba(" + r + ", " + g + ", " + b + ",1)";

            if (system.star.glow) {
                let glowStart = "rgba(" + r + ", " + g + ", " + b + ",0.4)";
                // let glowEnd = "rgba(" + r + ", " + g + ", " + b + ",0)";
                let glowEnd = "rgba(0,0,0,0)";
                let glow = context.createRadialGradient(system.position.x, system.position.y, drawRadius * 0.75, system.position.x, system.position.y, drawRadius * 3.5);
                glow.addColorStop(0, glowStart);
                glow.addColorStop(0.95, glowEnd);
                context.fillStyle = glow;
                context.beginPath();
                context.arc(system.position.x, system.position.y, drawRadius * 3.5, 0, 2 * Math.PI)
                context.fill();
            }

            if (system.star.jets) {
                let jetStart = "rgba(" + r + ", " + g + ", " + b + ",1)";
                let jetEnd = "rgba(" + r + ", " + g + ", " + b + ",0.0)";
                let jet = context.createRadialGradient(system.position.x, system.position.y, drawRadius * 0.5, system.position.x, system.position.y, drawRadius * 4);
                jet.addColorStop(0, jetStart);
                jet.addColorStop(1, jetEnd);
                context.fillStyle = jet;
                context.beginPath();
                context.moveTo(system.position.x, system.position.y)
                context.arc(system.position.x, system.position.y, drawRadius * 4, Math.PI * 0.5, Math.PI * 0.6);
                context.lineTo(system.position.x, system.position.y)
                context.fill();

                context.beginPath();
                context.moveTo(system.position.x, system.position.y)
                context.arc(system.position.x, system.position.y, drawRadius * 4, Math.PI * 1.5, Math.PI * 1.6);
                context.lineTo(system.position.x, system.position.y)
                context.fill();
            }


            if (system.star.fill) {
                let r = system.star.fill.r;
                let g = system.star.fill.g;
                let b = system.star.fill.b;
                context.fillStyle = "rgba(" + r + ", " + g + ", " + b + ",1)";
            } else {
                context.fillStyle = "#fff";
            }

            context.shadowColor = color;
            context.shadowBlur = drawRadius;
            context.strokeStyle = color;
            context.lineWidth = 0.5;

            if (system.star.class == "Blackhole") {
                context.lineWidth = 0;
                context.shadowColor = "#fff";
                context.shadowBlur = 10;

            }


            context.beginPath();
            context.arc(system.position.x, system.position.y, drawRadius, 0, 2 * Math.PI)
            context.fill();
            context.stroke();

            // context.beginPath();
            // context.fillStyle = "#fff";
            // context.shadowColor = "#000";
            // context.shadowBlur = 5;
            // context.fillText(system.name, system.position.x, system.position.y + 25)


            renderedSystems.push(system);
            // system.rendered = true;
        }


    }

    /*
    renderNames() {
        let canvas = this.layers.names.canvas;
        let context = this.layers.names.context;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.textAlign = "center";
        context.font = "15px 'Alegreya Sans SC'";
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = "rgba(255,255,255,0.5)";
        context.fillStyle = "#fff";
        context.strokeStyle = "#000";
        context.shadowColor = '#000';
        context.shadowBlur = 5;

        if (this.state.scale > 0.4) {
            for (let system of gameData.systems) {
                if (system.name) {
                    // if (!this.state.viewing || (this.state.viewing && system.owner == this.state.viewing.owner) || (this.state.viewing && !this.state.viewing.owner)) {
                    context.beginPath();
                    context.strokeText(system.name, system.position.x, system.position.y + 25)
                    context.fillText(system.name, system.position.x, system.position.y + 25)
                    // }
                }
            }
        }

    }


    drawOwnerConnections() {
        let systems = gameData.systems;

        let context = this.layers.ownerConnections.context;
        context.clearRect(0, 0, config.map.width, config.map.height);
        context.shadowBlur = 0;
        context.lineWidth = 13;


        for (let system of systems) {
            if (system.owner) {
                for (let connection of system.connections) {
                    if (connection.owner == system.owner) {
                        let color = system.owner.color;
                        context.strokeStyle = `hsla(${color.h}, ${color.s}, ${color.l}, 1)`;
                        context.beginPath();
                        context.moveTo(system.position.x, system.position.y)
                        context.lineTo(connection.position.x, connection.position.y)
                        context.stroke();
                    }
                }
            }
        }
    }

    drawSystemInfo() {

        let context = this.layers.systemInfo.context;
        context.clearRect(0, 0, config.map.width, config.map.height);

        let systems = gameData.systems;
        context.shadowColor = "#fff"

        for (let system of systems) {
            if (system.owner) {
                let color = system.owner.color;
                if (!color) console.log(system);


                // if (this.state.viewing && system.owner == this.state.viewing.owner) {
                //     context.shadowBlur = 10;
                //     // context.strokeStyle = "#fff";
                //     // context.stroke();
                // }


                let infoRadius = (5 * system.connections.length) + 10;
                context.lineWidth = 5;
                context.strokeStyle = `hsla(${color.h}, ${color.s}, ${color.l}, 1)`;
                // context.fillStyle = `hsla(${color.fill.h}, ${color.fill.s}, ${color.fill.l}, 1)`;
                context.fillStyle = `#0c0c0c`;
                context.beginPath();
                context.arc(system.position.x, system.position.y, infoRadius, 0, 2 * Math.PI);
                context.fill();
                context.stroke();

                context.shadowBlur = 0;

                // for (let connection of system.connections) {
                //     if (connection.owner == system.owner) {
                //         context.strokeStyle = `hsla(${color.outline.h}, ${color.outline.s}, ${color.outline.l}, 1)`;
                //         context.lineWidth = 13;
                //         context.beginPath();
                //         context.moveTo(system.position.x, system.position.y)
                //         context.lineTo(connection.position.x, connection.position.y)
                //         context.stroke();
                //     }
                // }

            }
        }
    }

    */

    renderDynamic() {

        const renderStartTime = performance.now();
        if (!ui.isViewOpen) {
            let systemInfoContext = this.layers.systemInfo.context;
            systemInfoContext.clearRect(0, 0, config.map.width, config.map.height);
            systemInfoContext.shadowColor = "#fff";
            systemInfoContext.shadowBlur = 0;

            let voronoiContext = this.layers.voronoi.context;
            voronoiContext.clearRect(0, 0, config.map.width, config.map.height);

            let ownerConnectionsContext = this.layers.ownerConnections.context;
            ownerConnectionsContext.clearRect(0, 0, config.map.width, config.map.height);
            ownerConnectionsContext.shadowBlur = 0;
            ownerConnectionsContext.lineWidth = 13;

            let namesContext = this.layers.names.context;
            namesContext.clearRect(0, 0, config.map.width, config.map.width);
            namesContext.textAlign = "center";
            namesContext.font = "15px 'Alegreya Sans SC'";
            namesContext.strokeStyle = "rgba(255,255,255,0.5)";
            namesContext.fillStyle = "#fff";
            namesContext.strokeStyle = "#000";
            namesContext.shadowColor = '#000';
            namesContext.shadowBlur = 5;

            let viewBoundary = this.state.viewBoundary;

            for (let row of this.state.cells) {
                for (let cell of row) {

                    if (ui.debug) {
                        ownerConnectionsContext.lineWidth = 5;
                        ownerConnectionsContext.strokeStyle = "#0f0";
                        ownerConnectionsContext.fillStyle = "#0f0";
                        ownerConnectionsContext.beginPath();
                        ownerConnectionsContext.moveTo(cell.x1, cell.y1);
                        ownerConnectionsContext.lineTo(cell.x1, cell.y2);
                        ownerConnectionsContext.moveTo(cell.x1, cell.y1);
                        ownerConnectionsContext.lineTo(cell.x2, cell.y1);
                        ownerConnectionsContext.stroke();
                        ownerConnectionsContext.beginPath();
                        ownerConnectionsContext.fillText(`[${cell.x1}, ${cell.y1}]`, cell.x1 + 10, cell.y1 + 20)
                        ownerConnectionsContext.lineWidth = 13;
                    }


                    if ( // IS CELL VISIBLE?
                        cell.x2 > (viewBoundary.left) &&
                        cell.x1 < (viewBoundary.right) &&
                        cell.y2 > (viewBoundary.top) &&
                        cell.y1 < (viewBoundary.bottom)
                    ) {
                        for (let system of cell.systems) {
                            if (system.owner) { // Only if owned
                                let color = system.owner.color;

                                if (this.state.drawVoronoi) {

                                    let voronoiCell = this.getVoronoiCell(system.voronoiCell)
                                    let halfedges = voronoiCell.halfedges;
                                    let borders = [];
                                    voronoiContext.fillStyle = `hsla(${color.h}, ${color.s}, ${color.l}, 0.5)`; //${(0.5 * (system.control / 100)).toFixed(2) }
                                    // voronoiContext.fillStyle = `rgba(0, 50, 200, ${(system.control / 100).toFixed(2)})`;
                                    voronoiContext.strokeStyle = `hsla(${color.h}, ${color.s}, ${color.l}, 0.5)`;
                                    voronoiContext.lineWidth = 2;
                                    voronoiContext.beginPath();
                                    voronoiContext.moveTo(halfedges[0].getStartpoint().x, halfedges[0].getStartpoint().y);
                                    for (let i = 0; i < halfedges.length; i++) {
                                        let halfedge = halfedges[i]
                                        let edge = halfedge.edge;
                                        voronoiContext.lineTo(halfedge.getEndpoint().x, halfedge.getEndpoint().y)
                                        let neighbor = null;
                                        
                                        if ( edge.lSite && halfedge.site && edge.lSite.voronoiId != halfedge.site.voronoiId) {
                                            neighbor = gameData.systems.filter(system => system.voronoiCell == edge.lSite.voronoiId)[0];
                                        } else if (edge.rSite && halfedge.site && edge.rSite.voronoiId != halfedge.site.voronoiId) {
                                            neighbor = gameData.systems.filter(system => system.voronoiCell == edge.rSite.voronoiId)[0];
                                        } 
                                        if (!neighbor || (neighbor && neighbor.owner != system.owner)) { borders.push(edge)}
                                    }
                                    voronoiContext.fill();
                                    // voronoiContext.stroke(); // Draw cell borders

                                    voronoiContext.lineCap = "round";
                                    voronoiContext.strokeStyle = "#ffffff";
                                    voronoiContext.lineWidth = 5;
                                    for (let border of borders) {
                                        voronoiContext.beginPath();
                                        voronoiContext.moveTo(border.va.x, border.va.y)
                                        voronoiContext.lineTo(border.vb.x, border.vb.y)
                                        voronoiContext.stroke();
                                    }

                                } else {

                                    let infoRadius = (5 * system.orbitals.length) + 10;
                                    systemInfoContext.lineWidth = 5;
                                    systemInfoContext.strokeStyle = `hsla(${color.h}, ${color.s}, ${color.l}, 1)`;
                                    // context.fillStyle = `hsla(${color.fill.h}, ${color.fill.s}, ${color.fill.l}, 1)`;
                                    systemInfoContext.fillStyle = system.state.war ? "rgb(150,0,0)" : '#0c0c0c';
                                    systemInfoContext.beginPath();
                                    systemInfoContext.arc(system.position.x, system.position.y, infoRadius, 0, 2 * Math.PI);
                                    systemInfoContext.fill();
                                    systemInfoContext.stroke();

                                    
                                    for (let connection of system.connections) {
                                        if (connection.owner == system.owner) {
                                            ownerConnectionsContext.strokeStyle = `hsla(${color.h}, ${color.s}, ${color.l}, 1)`;
                                            ownerConnectionsContext.beginPath();
                                            ownerConnectionsContext.moveTo(system.position.x, system.position.y)
                                            ownerConnectionsContext.lineTo(connection.position.x, connection.position.y)
                                            ownerConnectionsContext.stroke();
                                        }
                                    }
                                }

                                if (this.state.scale > 0.4) {
                                    if (system.name) {
                                        // if (!this.state.viewing || (this.state.viewing && system.owner == this.state.viewing.owner) || (this.state.viewing && !this.state.viewing.owner)) {
                                        namesContext.beginPath();
                                        namesContext.strokeText(system.name, system.position.x, system.position.y + 25)
                                        namesContext.fillText(system.name, system.position.x, system.position.y + 25)
                                        // }
                                    }
                                }

                            }
                        }
                    }
                }
            }

            if (ui.debug) {
                ownerConnectionsContext.lineWidth = 5;
                ownerConnectionsContext.strokeStyle = "#f80";
                ownerConnectionsContext.fillStyle = "#f80";
                ownerConnectionsContext.beginPath();
                ownerConnectionsContext.moveTo(this.state.viewBoundary.left, this.state.viewBoundary.top);
                ownerConnectionsContext.lineTo(this.state.viewBoundary.right, this.state.viewBoundary.top);
                ownerConnectionsContext.lineTo(this.state.viewBoundary.right, this.state.viewBoundary.bottom);
                ownerConnectionsContext.lineTo(this.state.viewBoundary.left, this.state.viewBoundary.bottom);
                ownerConnectionsContext.lineTo(this.state.viewBoundary.left, this.state.viewBoundary.top);
                ownerConnectionsContext.stroke();
            }
        }

        const renderEndTime = performance.now();
        gameData.performance.render.galaxyMap = (renderEndTime - renderStartTime).toFixed(2);

        window.requestAnimationFrame(() => {
            this.renderDynamic()
        })

    }

}