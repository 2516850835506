import { loadGameData } from "./utilities"

var gameData = loadGameData() || {
    seed: false, //'demo',
    species: [],
    civilizations: [],
    characters: [],
    orbitals: [],
    stars: [],
    systems: [],
    resources: [],
    eventHistory: [

    ],
    performance: {
        logic: { tps: 0, time: 0 },
        render: { galaxyMap: 0, systemMap: 0, localMap: 0 }
    }
}

if (!gameData.seed) {
    let seed = location.hash.replace('#', '');
    gameData.seed = seed != '' ? seed : window.crypto.randomUUID();
}

location.hash = gameData.seed;

export { gameData }