import { definitions } from "../Definitions";
import { randnormal } from "../utilities";

export default class ResourceDeposit {

    constructor(resource, orbitalClass) {
        let resourceClass = definitions.resources[resource];
        this.name = resourceClass.name;
        this.productionRate = resourceClass.productionRate;
        this.value = Math.floor(resourceClass.value * randnormal(orbitalClass.resources[resource].min, orbitalClass.resources[resource].max));
    }

    addValue(value) {
        if (value > 0) {
            this.value += value;
        }
    }

    produce(efficiency=1)  {
        let produced = this.productionRate * efficiency;
        if (this.value > produced) {
            this.value -= produced
            return produced;
        } 

        produced = this.value;
        this.value = 0;
        return produced;

    }

}