import Voronoi from 'voronoi';

import config from "../../js/ConfigManager";
import { distance, randfloat, randint } from "../../js/utilities";
import Component from "../Component";
import template from "./template.hbs";
import infoTemplate from "./info.hbs";

import { gameData } from "../../js/gameData";
import { ui } from '../../js/ui';


export default class LocalMap extends Component {

    constructor(parent) {

        super()

        this.createElement({ parent, template })

        this.layerSurface = this.element.querySelector(".local-map__layers");
        this.selector = this.element.querySelector(".local-map__selector");
        this.infoPanel = this.element.querySelector(".local-map-info");

        this.layers = {
            static: {},
            names: {},
            dynamic: {},
        }

        this.state = {
            scale: 0.5,
            mouse: false,
            dragDist: 0,
            translate: {
                x: -config.systemMap.width / 2,
                y: -config.systemMap.height / 2
            },
            backgroundTranslate: {
                x: -window.innerWidth / 2,
                y: -window.innerHeight / 2
            },
            keys: {},
            viewing: false,
            tracking: false
        }

        this.initLayers();
        this.bindMapEvents();
        this.watchKeys();
        this.updateView();
        // this.renderStars();

    }


    initLayers() {

        this.layerSurface.style.width = `${config.systemMap.width}px`;
        this.layerSurface.style.height = `${config.systemMap.height}px`;

        for (let x in this.layers) {
            let layer = this.layers[x];
            layer.canvas = document.createElement("canvas");
            layer.canvas.width = config.systemMap.width;
            layer.canvas.height = config.systemMap.height;
            layer.canvas.classList.add("local-map__layer");
            layer.context = layer.canvas.getContext("2d");
            layer.context.fillStyle = "#fff";
            layer.context.strokeStyle = "#fff";
            this.layerSurface.appendChild(layer.canvas)
        }

        this.layers.starGlowLayer = { canvas: document.createElement("canvas") };
        this.layers.starGlowLayer.context = this.layers.starGlowLayer.canvas.getContext("2d");
        this.layers.starGlowLayer.canvas.width = window.innerWidth * 2;
        this.layers.starGlowLayer.canvas.height = window.innerWidth * 2;
        this.layers.starGlowLayer.canvas.classList.add("local-map__starglow-layer")
        this.layers.starGlowLayer.canvas.style.transform = `translate(${-window.innerWidth}px, ${-window.innerWidth}px)`;
        this.element.prepend(this.layers.starGlowLayer.canvas);

        this.layers.starLayer = { canvas: document.createElement("canvas") };
        this.layers.starLayer.context = this.layers.starLayer.canvas.getContext("2d");
        this.layers.starLayer.canvas.width = window.innerWidth * 2;
        this.layers.starLayer.canvas.height = window.innerHeight * 2;
        this.element.prepend(this.layers.starLayer.canvas);

    }


    updateInfo() {

        let data = {
            system: this.state.system,
            object: this.state.viewing
        }

        this.infoPanel.innerHTML = infoTemplate(data);


    }

    getSelectedOrbital(x, y) {
        var a = x - config.systemMap.width / 2;
        var b = y - config.systemMap.height / 2;
        var c = Math.sqrt(a * a + b * b) - 500;
        let orbit = Math.round(this.state.system.orbits.length * (c / ((config.systemMap.width / 2) - 1000 - this.starRadius)));
        orbit = orbit > this.state.system.orbits.length || orbit < 1 ? false : orbit;

        let object = orbit ? this.state.system.orbits[orbit - 1].object : false;

        if (object) {
            console.log(object);
            this.state.viewing = object;
            this.state.tracking = true;
        } else {
            this.state.viewing = false;
            this.state.tracking = false;
        }

        this.updateInfo()
    }

    watchKeys() {

        if (ui.currentView == ui.views.LocalMap) {
            if (ui.keys.KeyW) { this.state.translate.y += config.systemMap.scrollSpeed; }
            if (ui.keys.KeyS) { this.state.translate.y -= config.systemMap.scrollSpeed; }
            if (ui.keys.KeyA) { this.state.translate.x += config.systemMap.scrollSpeed; }
            if (ui.keys.KeyD) { this.state.translate.x -= config.systemMap.scrollSpeed; }
        }

        window.requestAnimationFrame(() => { this.watchKeys() })
    }

    updateView() {
        // this.layers.static.canvas.style.transform = `translate(${this.state.translate.x}px, ${this.state.translate.y}px) scale(${this.state.scale.toFixed(2)})`;
        // this.layers.dynamic.canvas.style.transform = `translate(${this.state.translate.x}px, ${this.state.translate.y}px) scale(${this.state.scale.toFixed(2)})`;
        this.layerSurface.style.transformOrigin = `${-this.state.translate.x}px ${-this.state.translate.y}px`;
        this.layerSurface.style.transform = `translate(${this.state.translate.x}px, ${this.state.translate.y}px) scale(${this.state.scale.toFixed(2)})`;
        this.layers.starLayer.canvas.style.transform = `translate(${this.state.backgroundTranslate.x}px, ${this.state.backgroundTranslate.y}px)`;
        window.requestAnimationFrame(() => this.updateView())
    }

    dragEnd(e) {
        if (e.which == 1) {
            this.state.mouse = false;
            this.state.dragDist = 0;
            this.element.style.cursor = "default";
        }
    }

    dragMove(e) {
        if (e.which == 1 && (this.layerSurface.offsetWidth > this.element.offsetWidth || this.layerSurface.offsetHeight > this.element.offsetHeight)) {
            let mouse = { x: e.clientX, y: e.clientY };
            this.element.style.cursor = "move";
            if (this.state.mouse) {
                let boundaries = {
                    xRight: (this.element.offsetWidth / 2) * -0.5,
                    xLeft: (this.layerSurface.offsetWidth - this.element.offsetWidth / 2) * -1.5,
                    yTop: ((this.element.offsetHeight / 2) * -0.5) + 50,
                    yBottom: (this.layerSurface.offsetHeight - this.element.offsetHeight / 2) * -1.5
                }
                let dist = distance(this.state.mouse, mouse);
                let x = (mouse.x - this.state.mouse.x) * config.systemMap.dragSensitivity;
                let y = (mouse.y - this.state.mouse.y) * config.systemMap.dragSensitivity;
                this.state.dragDist += dist;
                this.state.translate.x += x;
                this.state.translate.y += y;

                if (this.state.translate.x > boundaries.xRight) { this.state.translate.x = boundaries.xRight; }
                if (this.state.translate.x < boundaries.xLeft) { this.state.translate.x = boundaries.xLeft; }

                if (this.state.translate.y > boundaries.yTop) { this.state.translate.y = boundaries.yTop; }
                if (this.state.translate.y < boundaries.yBottom) { this.state.translate.y = boundaries.yBottom; }

                this.state.backgroundTranslate.x += x * 0.01;
                this.state.backgroundTranslate.y += y * 0.01;

                // this.layerSurface.style.transformOrigin = `${this.state.translate.x * -1}px ${this.state.translate.y * -1}px`;

                this.state.tracking = false;
            }
            this.state.mouse = mouse;
        }
    }

    bindMapEvents() {

        this.element.addEventListener("contextmenu", e => { e.preventDefault(); })

        window.addEventListener("mouseleave", (e) => { this.dragEnd(e) });
        this.element.addEventListener("mouseleave", (e) => { this.dragEnd(e) });
        this.element.addEventListener("mouseup", (e) => { if (this.state.dragDist < 5) this.getSelectedOrbital(e.offsetX, e.offsetY); this.dragEnd(e) });
        this.element.addEventListener("mousemove", (e) => {
            this.dragMove(e);
        });

        this.element.addEventListener("mousewheel", e => {
            if (ui.currentView == ui.views.LocalMap) {
                this.state.scale += e.deltaY < 0 ? 0.05 : -0.05;
                this.state.scale = Math.min(Math.max(this.state.scale, 0.1), 1);
            }
        });

    }

    renderStars() {
        let canvas = this.layers.starLayer.canvas;
        let context = this.layers.starLayer.context;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "#fff";
        context.shadowBlur = 3;
        for (let i = 0; i < 1000; i++) {
            let x = Math.floor(Math.random() * canvas.width);
            let y = Math.floor(Math.random() * canvas.height);
            let radius = Math.random() * 1 + 0.1;
            context.beginPath();
            context.arc(x, y, radius, 0, 2 * Math.PI);
            context.fill();
        }

    }


    renderStatic() {

        let canvas = this.layers.static.canvas;
        let context = this.layers.static.context;
        context.clearRect(0, 0, canvas.width, canvas.height);

        this.objectRadius = Math.max(Math.min(this.state.object.radius.factor * 50, 1000), 50);

        context.fillStyle = "#fff";
        context.shadowColor = "rgba(255,255,255,1)";
        context.shadowBlur = 30;
        context.lineWidth = 2;

        // Draw Body
        context.beginPath();
        context.arc(canvas.width / 2, canvas.height / 2, this.objectRadius, 0, 2 * Math.PI)
        context.fill();

        for (let i = 0; i < this.state.object.rings.length; i++) {
            let ring = this.state.object.rings[i];
            context.strokeStyle = `rgba(255,255,255,${ring.transparency})`;
            context.lineWidth = this.objectRadius * ring.lineWidth;
            context.beginPath();
            context.arc(canvas.width / 2, canvas.height / 2, this.objectRadius * ring.radiusMultiplier + ring.baseDistance, 0, 2 * Math.PI)
            context.stroke();
        }

        // Iterate each orbit and render if an object is present
        for (let i = 0; i < 10; i++) {

            let orbitRadius = (this.objectRadius + ((i + 1) * 100) + (this.objectRadius * 0.05));
            context.lineWidth = 3;
            context.beginPath();
            context.arc(canvas.width / 2, canvas.height / 2, orbitRadius, 0, 2 * Math.PI)
            // context.stroke();

            // context.beginPath();
            // context.arc(canvas.width/2 + orbitRadius, canvas.height / 2, objectRadius, 0, 2 * Math.PI)
            // context.fill();
        }



    }

    renderNames() {
        let canvas = this.layers.names.canvas;
        let context = this.layers.names.context;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.textAlign = "center";
        context.font = "15px 'Alegreya Sans SC'";
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = "rgba(255,255,255,0.5)";
        context.fillStyle = "#fff";
        context.shadowColor = '#000';
        context.shadowBlur = 5;

        if (this.state.scale > 0.5) {
            for (let system of gameData.systems) {
                if (system.name) {
                    if (!this.state.viewing || (this.state.viewing && system.owner == this.state.viewing.owner) || (this.state.viewing && !this.state.viewing.owner)) {
                        context.beginPath();
                        context.fillText(system.name, system.position.x, system.position.y + 25)
                    }
                }
            }
        }

    }


    drawSystemInfo(context) {
        let systems = gameData.systems;
        context.shadowColor = "#fff"

        for (let system of systems) {
            if (system.owner) {
                let color = system.owner.color;
                if (!color) console.log(system);

                let infoRadius = (5 * system.connections.length) + 10;
                context.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
                context.beginPath();
                context.arc(system.position.x, system.position.y, infoRadius, 0, 2 * Math.PI);
                context.fill();

                if (this.state.viewing && system.owner == this.state.viewing.owner) {
                    context.shadowBlur = 10;
                    context.lineWidth = this.state.scale < 0.7 ? 2 : 1;
                    context.strokeStyle = "#fff";
                    context.stroke();
                }

                context.shadowBlur = 0;

                for (let connection of system.connections) {
                    if (connection.owner == system.owner) {
                        context.strokeStyle = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
                        context.lineWidth = 13;
                        context.beginPath();
                        context.moveTo(system.position.x, system.position.y)
                        context.lineTo(connection.position.x, connection.position.y)
                        context.stroke();
                    }
                }


            }
        }
    }

    renderDynamic() {

        const renderStartTime = performance.now();

        if (ui.views.LocalMap.isOpen) {
            let canvas = this.layers.dynamic.canvas;
            let context = this.layers.dynamic.context;
            let pi = Math.PI;


            context.clearRect(0, 0, canvas.width, canvas.height);
            context.shadowColor = "#fff";
            context.shadowBlur = 20;
            context.fillStyle = "#fff"




            /*
            
            for (let i = 0; i < this.state.system.orbits.length; i++) {
                let orbit = this.state.system.orbits[i];
                if (orbit.object) {

                    let object = orbit.object;

                    context.shadowColor = "#fff";
                    context.shadowOffsetX = 0;
                    context.shadowOffsetY = 0;
                    context.shadowBlur = 20;

                    let objectRadius = Math.max(Math.min(30 * object.radius, 100), 10);
                    let orbitRadius = (this.orbitSpacing * (i + 1)) + 500;

                    let originX = canvas.width / 2;
                    let originY = canvas.height / 2;

                    let theta = object.angle * (pi / 180);
                    let objectX = originX + orbitRadius * Math.cos(theta)
                    let objectY = originY + orbitRadius * Math.sin(theta);

                    object.angle -= 0.05 / (i + 1);
                    object.angle += object.angle < 0 ? 360 : 0;

                    context.fillStyle = "#fff";
                    context.beginPath();
                    context.arc(objectX, objectY, objectRadius, 0, 2 * Math.PI)
                    context.fill();

                    if (object && object == this.state.viewing) {

                        if (this.state.tracking) {
                            let x = -canvas.width / 2 - (objectX - canvas.width / 2);
                            let y = -canvas.height / 2 - (objectY - canvas.height / 2);
                            let deltaX = x - this.state.translate.x;
                            let deltaY = y - this.state.translate.y;
                            this.state.translate.x = x;
                            this.state.translate.y = y;
                            this.state.backgroundTranslate.x -= -deltaX * 0.05;
                            this.state.backgroundTranslate.y -= -deltaY * 0.05;
                            // this.layerSurface.style.transformOrigin = `${-x}px ${-y}px`;
                        }

                        context.lineWidth = 10;

                        context.beginPath()
                        context.arc(objectX, objectY, objectRadius + 50, 0, 2 * Math.PI);
                        context.stroke()



                    }

                }
            }

            */

            canvas = this.layers.starGlowLayer.canvas;
            context = this.layers.starGlowLayer.context;


            if (this.state.object.system.star.color.noGlow !== true) {

                context.clearRect(0, 0, canvas.width, canvas.height)

                let r = this.state.object.system.star.color.r;
                let g = this.state.object.system.star.color.g;
                let b = this.state.object.system.star.color.b;

                let maxOrbits = config.systems.orbitals.maxCount;
                // let brightness = Math.pow(((maxOrbits - this.state.object.orbit) / maxOrbits) * 0.85 + 0.05, 2);
                let brightness = Math.pow(((maxOrbits - this.state.object.orbit) / maxOrbits) * 0.75 + 0.05, 2);

                let theta = (this.state.object.angle - 180) * (Math.PI / 180);
                let glowX = canvas.width / 2 + canvas.width * Math.cos(theta)
                let glowY = canvas.height / 2 + canvas.height * Math.sin(theta);

                let glowStart = `rgba(${r}, ${g}, ${b}, ${brightness})`;
                let glowEnd = `rgba(${r}, ${g}, ${b}, 0)`;
                let glow = context.createRadialGradient(glowX, glowY, 0, glowX, glowY, canvas.width);
                glow.addColorStop(0, glowStart);
                glow.addColorStop(1, glowEnd);
                context.fillStyle = glow;

                context.beginPath();
                context.arc(glowX, glowY, canvas.width, 0, 2 * Math.PI)
                context.fill();
            }



        }

        const renderEndTime = performance.now();
        gameData.performance.render.localMap = (renderEndTime - renderStartTime).toFixed(2);

        window.requestAnimationFrame(() => {
            this.renderDynamic();
        })
    }

    reset() {
        this.state.viewing = false;
        this.state.tracking = false;
        this.state.scale = 0.5;
        this.state.translate = {
            x: -config.systemMap.width / 2,
            y: -config.systemMap.height / 2
        };
        this.state.backgroundTranslate = {
            x: -window.innerWidth / 2,
            y: -window.innerHeight / 2
        };
    }

    render(object) {
        this.state.object = object;

        this.reset()
        this.updateInfo();
        this.renderStars();
        this.renderStatic();
        // this.renderNames();
        this.renderDynamic();
    }

}