import template from "./template.hbs";
import metricsTemplate from "./metrics.hbs";
import Component from "../Component";
import { ui } from "../../js/ui";
import { gameData } from "../../js/gameData";

export default class PerformanceMonitor extends Component {

    constructor(parent) {

        super();
        this.createElement({ parent, template })
        this.update();
        this.toggle();

    }

    toggle() {
        if (ui.debug) {
            this.element.style.display = "block";
        } else {
            this.element.style.display = "none";
        }
    }

    update() {
        if (ui.debug) {
            
            let metrics = {
                performance: gameData.performance,
                systems: gameData.systems.length,
                species: gameData.species.length,
                civilizations: gameData.civilizations.length,
                characters: gameData.characters.length,
                orbitals: gameData.orbitals.length
            }

            this.element.innerHTML = metricsTemplate(metrics);
        } 

        window.setTimeout(() => { this.update(); }, 1000)
    }

}

