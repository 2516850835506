<div class="music-player ui__panel ui__panel--right ui__panel--floating">


    <div class="music-player__track">

        <div class="music-player__title"></div>
        <div class="music-player__attribution"> - <a href='http://www.scottbuckley.com.au' target='_blank'>Scott
                Buckley</a></div>


    <div class="music-player__controls">
        <span class="music-player__play ui__button">
            <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 124.512 124.512" style="enable-background:new 0 0 124.512 124.512;"
                xml:space="preserve">
                <g>
                    <path d="M113.956,57.006l-97.4-56.2c-4-2.3-9,0.6-9,5.2v112.5c0,4.6,5,7.5,9,5.2l97.4-56.2
		C117.956,65.105,117.956,59.306,113.956,57.006z" />
                </g>
            </svg>

        </span>
        <span class="music-player__pause ui__button">
            <svg version="1.1"xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 124.5 124.5" style="enable-background:new 0 0 124.5 124.5;"
                xml:space="preserve">
                <g>
                    <path
                        d="M116.35,124.5c3.3,0,6-2.699,6-6V6c0-3.3-2.7-6-6-6h-36c-3.3,0-6,2.7-6,6v112.5c0,3.301,2.7,6,6,6H116.35z" />
                    <path
                        d="M44.15,124.5c3.3,0,6-2.699,6-6V6c0-3.3-2.7-6-6-6h-36c-3.3,0-6,2.7-6,6v112.5c0,3.301,2.7,6,6,6H44.15z" />
                </g>
            </svg>
        </span>
        <span class="music-player__next ui__button">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        d="M16 12.667L5.777 19.482A.5.5 0 0 1 5 19.066V4.934a.5.5 0 0 1 .777-.416L16 11.333V5a1 1 0 0 1 2 0v14a1 1 0 0 1-2 0v-6.333z" />
                </g>
            </svg>

        </span>
        <span class="music-player__random ui__button"><i class="fas fa-random"></i></span>
    </div>


        <span class="music-player__seek-wrapper">
            <div class="music-player__seek">
                <div class="music-player__progress"></div>
            </div>
        </span>
    </div>

    <audio autoplay preload="auto"></audio>
</div>