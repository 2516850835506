import View from "../View";
import config from "../../js/ConfigManager";
import template from "./template.hbs";

export default class SettingsView extends View {

    constructor() {

        super(template, ['.settings-button']);
             


    }

    close() {
        config.save();
        super.close();
    }

    // _resolvePath(path, separator='.') {
    //     let properties = path.split(separator);
    //     return properties.reduce((prev, curr) => prev && prev[curr], this.config)
    // }

    // get(path) {
    //     return path.split('.').reduce((p,c)=>p&&p[c]||null, this.config);
    // }

    // set(path, value) {
    //     path.split('.').reduce((o,p,i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, this.config)
    //     localStorage.setItem("config", JSON.stringify(this.config));
    // }

}
