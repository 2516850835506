import View from "../View";
import config from "../../js/ConfigManager";
import template from "./template.hbs";

import { gameData } from "../../js/gameData";
import LocalMap from "../../components/LocalMap";


export default class LocalMapView extends View {

    constructor() {
        super(template);
        this.viewElement.style.background = "#050505";
        this.map = new LocalMap(this.element.querySelector(".system-map-view__map"));
    }

    viewLocal(body) {
        // this.element.querySelector(".system-map__system-name").innerText = system.name || system.catalogName;
        this.map.render(body);
        this.open();
    }
   
}
