import config from "../ConfigManager";
import { definitions } from "../Definitions";
import { gameData } from "../gameData";
import {randfloat, randint, randnormal, rng} from "../utilities";
import ResourceDeposit from "./ResourceDeposit";

const earthMetrics = {
    // mass: { value: 1.989 * (10**30), units: "kg" },
    radius: { value: 6378, units: "km"},
    // temp: {value: 5778, units: "K" },
    // luminosity: { value: (4 * Math.PI * (696340 ** 2) * sbConstant * (5778 ** 4)), units: "W"}
}

function selectClass(system, orbit) {
      
    let options = [];
    
    if (config.systems.orbitals.types.planets) options = options.concat( definitions.celestials.planets );
    if (config.systems.orbitals.types.asteroids) options = options.concat( definitions.celestials.asteroids);
    if (config.systems.orbitals.types.planetoids) options = options.concat( definitions.celestials.planetoids);
    
    let orbitalClass = false;
    
    while (!orbitalClass ) {
        let choice = options[randint(0, options.length-1)];
        orbitalClass = (randint(0,100) < choice.rarity &&
                      system.orbits[orbit].temperature >= choice.tempLimits.min && 
                      system.orbits[orbit].temperature <= choice.tempLimits.max) 
                    ? choice : false;
    }
    
    return orbitalClass;
}


export default class Orbital {
    
    constructor(system, orbit) {
                
        this.class= selectClass(system, orbit);

        this.system = system;
        this.orbit = orbit;

        this.angle = randint(0,359);

        let radiusFactor = randfloat(this.class.radius.min,this.class.radius.max);
        this.radius = {factor: radiusFactor.toPrecision(2), value: Math.round(radiusFactor * earthMetrics.radius.value)};

        this.axialTilt = randnormal(-90, 90, 1).toFixed(2);

        this.temperature = Math.round(this.system.orbits[this.orbit].temperature);
        this.tempF =  Math.floor(((( this.temperature - 273.15 ) * 9) / 5) + 32);

        this.name = this.class.designator + "-" + (this.system.orbitals.length + 1) + this.orbit;

        this.habitable = (this.class.habitable && this.temperature > config.life.minTemp && this.temperature < config.life.maxTemp);
        this.populated = false;

        this.rings = [];
        if (this.class.ringsAllowed && rng() < config.systems.orbitals.ringChance) {
            for (let i=0; i<4; i++) {
                let transparency = randfloat(0.15, 0.35);
                let lineWidth = randfloat(0.25, 0.5);
                let radiusMultiplier = randint(1.25, 1.75);
                let baseDistance = (i+1) * randint(10,30);
                this.rings.push({transparency, lineWidth, radiusMultiplier, baseDistance})
            }
        }

        this.resources = {};
        for (let type in definitions.resources) {
            this.resources[type] = new ResourceDeposit(type, this.class);
        }
        // console.log(this.resources)

        if (this.rings.length) { this.resources.mineral.addValue(definitions.resources.mineral.value) }
        if (this.system.star.jets) { this.resources.exotic.addValue(definitions.resources.exotic.value) }

        this.updates = {
            self: true,
            data: true
        }

        gameData.orbitals.push(this);
      
    }
    
   tick() {
    
    // UPDATE ORBIT POSITION
        this.angle -= 0.05 / (this.orbit + 1);
        this.angle += this.angle < 0 ? 360 : 0;
   }

   initOrbits() {
    for (let i = 0; i < config.systems.orbitals.maxSatellites; i++) {
        let temp = this.temperature;
        this.orbits[i] = { temperature: temp, object: null };
    }
}

}
