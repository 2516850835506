import Voronoi from 'voronoi';

import config from "../../js/ConfigManager";
import { distance, randfloat, randint } from "../../js/utilities";
import Component from "../Component";
import template from "./template.hbs";
import infoTemplate from "./info.hbs";

import { gameData } from "../../js/gameData";
import { ui } from '../../js/ui';


export default class SystemMap extends Component {

    constructor(parent) {

        super()

        this.createElement({ parent, template })

        this.layerSurface = this.element.querySelector(".system-map__layers");
        this.selector = this.element.querySelector(".system-map__selector");
        this.infoPanel = this.element.querySelector(".system-map-info");

        this.layers = {
            static: {},
            names: {},
            dynamic: {},
        }

        this.state = {
            scale: 0.5,
            mouse: false,
            dragDist: 0,
            translate: {
                x: -config.systemMap.width / 2,
                y: -config.systemMap.height / 2
            },
            backgroundTranslate: {
                x: -window.innerWidth / 2,
                y: -window.innerHeight / 2
            },
            keys: {},
            viewing: false,
            tracking: false
        }

        this.initLayers();
        this.bindMapEvents();
        this.watchKeys();
        this.updateView();
        this.renderStars();

    }


    initLayers() {

        this.layerSurface.style.width = `${config.systemMap.width}px`;
        this.layerSurface.style.height = `${config.systemMap.height}px`;

        for (let x in this.layers) {
            let layer = this.layers[x];
            layer.canvas = document.createElement("canvas");
            layer.canvas.width = config.systemMap.width;
            layer.canvas.height = config.systemMap.height;
            layer.canvas.classList.add("system-map__layer");
            layer.context = layer.canvas.getContext("2d");
            layer.context.fillStyle = "#fff";
            layer.context.strokeStyle = "#fff";
            this.layerSurface.appendChild(layer.canvas)
        }

        this.starLayer = { canvas: document.createElement("canvas") };
        this.starLayer.context = this.starLayer.canvas.getContext("2d");
        this.starLayer.canvas.width = window.innerWidth * 2;
        this.starLayer.canvas.height = window.innerHeight * 2;
        this.element.prepend(this.starLayer.canvas);

    }


    updateInfo() {

        let data = {
            system: this.state.system.summary,
            object: this.state.viewing
        }

        this.infoPanel.innerHTML = infoTemplate(data);

    }

    getSelectedOrbital(x, y, openLocalMap = false) {
        var a = x - config.systemMap.width / 2;
        var b = y - config.systemMap.height / 2;
        var c = Math.sqrt(a * a + b * b) - 500;
        let orbit = Math.round(this.state.system.orbits.length * (c / ((config.systemMap.width / 2) - 1000 - this.starRadius)));
        orbit = orbit > this.state.system.orbits.length || orbit < 1 ? false : orbit;

        let object = orbit ? this.state.system.orbits[orbit - 1].object : false;
        if (object) {
            if (openLocalMap) ui.views.LocalMap.viewLocal(object);
            this.state.viewing = object;
            this.state.tracking = true;
        } else {
            this.state.viewing = false;
            this.state.tracking = false;
        }

        this.updateInfo()
    }

    watchKeys() {

        if (ui.currentView == ui.views.SystemMap) {
            if (ui.keys.KeyW) { this.state.translate.y += config.systemMap.scrollSpeed; }
            if (ui.keys.KeyS) { this.state.translate.y -= config.systemMap.scrollSpeed; }
            if (ui.keys.KeyA) { this.state.translate.x += config.systemMap.scrollSpeed; }
            if (ui.keys.KeyD) { this.state.translate.x -= config.systemMap.scrollSpeed; }
            if (ui.keys.Space && this.state.viewing) { ui.views.LocalMap.viewLocal(this.state.viewing); }
            if (ui.keys.Escape) {
                if (this.state.viewing) {
                    this.getSelectedOrbital(null, null);
                }
            }
        }


        window.requestAnimationFrame(() => { this.watchKeys() })
    }

    updateView() {
        // this.layers.static.canvas.style.transform = `translate(${this.state.translate.x}px, ${this.state.translate.y}px) scale(${this.state.scale.toFixed(2)})`;
        // this.layers.dynamic.canvas.style.transform = `translate(${this.state.translate.x}px, ${this.state.translate.y}px) scale(${this.state.scale.toFixed(2)})`;
        this.layerSurface.style.transformOrigin = `${-this.state.translate.x}px ${-this.state.translate.y}px`;
        this.layerSurface.style.transform = `translate(${this.state.translate.x}px, ${this.state.translate.y}px) scale(${this.state.scale.toFixed(2)})`;
        this.starLayer.canvas.style.transform = `translate(${this.state.backgroundTranslate.x}px, ${this.state.backgroundTranslate.y}px)`;
        window.requestAnimationFrame(() => this.updateView())
    }

    dragEnd(e) {
        if (e.which == 1) {
            this.state.mouse = false;
            this.state.dragDist = 0;
            this.element.style.cursor = "default";
        }
    }

    dragMove(e) {
        if (e.which == 1 && (this.layerSurface.offsetWidth > this.element.offsetWidth || this.layerSurface.offsetHeight > this.element.offsetHeight)) {
            let mouse = { x: e.clientX, y: e.clientY };
            this.element.style.cursor = "move";
            if (this.state.mouse) {
                let boundaries = {
                    xRight: (this.element.offsetWidth / 2) * -0.5,
                    xLeft: (this.layerSurface.offsetWidth - this.element.offsetWidth / 2) * -1.5,
                    yTop: ((this.element.offsetHeight / 2) * -0.5) + 50,
                    yBottom: (this.layerSurface.offsetHeight - this.element.offsetHeight / 2) * -1.5
                }
                let dist = distance(this.state.mouse, mouse);
                let x = (mouse.x - this.state.mouse.x) * config.systemMap.dragSensitivity;
                let y = (mouse.y - this.state.mouse.y) * config.systemMap.dragSensitivity;
                this.state.dragDist += dist;
                this.state.translate.x += x;
                this.state.translate.y += y;

                if (this.state.translate.x > boundaries.xRight) { this.state.translate.x = boundaries.xRight; }
                if (this.state.translate.x < boundaries.xLeft) { this.state.translate.x = boundaries.xLeft; }

                if (this.state.translate.y > boundaries.yTop) { this.state.translate.y = boundaries.yTop; }
                if (this.state.translate.y < boundaries.yBottom) { this.state.translate.y = boundaries.yBottom; }

                this.state.backgroundTranslate.x += x * 0.01;
                this.state.backgroundTranslate.y += y * 0.01;

                // this.layerSurface.style.transformOrigin = `${this.state.translate.x * -1}px ${this.state.translate.y * -1}px`;

                this.state.tracking = false;
            }
            this.state.mouse = mouse;
        }
    }

    bindMapEvents() {

        this.element.addEventListener("contextmenu", e => { e.preventDefault(); })

        window.addEventListener("mouseleave", (e) => { this.dragEnd(e) });
        this.element.addEventListener("mouseleave", (e) => { this.dragEnd(e) });
        this.element.addEventListener('dblclick', e => { if (this.state.dragDist < 5) { this.getSelectedOrbital(e.offsetX, e.offsetY, true); } this.dragEnd(e) });
        this.element.addEventListener("mouseup", (e) => { if (this.state.dragDist < 5) this.getSelectedOrbital(e.offsetX, e.offsetY); this.dragEnd(e) });
        this.element.addEventListener("mousemove", (e) => {
            this.dragMove(e);
        });

        this.element.addEventListener("mousewheel", e => {
            if (ui.currentView == ui.views.SystemMap) {
                this.state.scale += e.deltaY < 0 ? 0.05 : -0.05;
                this.state.scale = Math.min(Math.max(this.state.scale, 0.25), 1);
            }
        });


    }

    renderStars() {
        let canvas = this.starLayer.canvas;
        let context = this.starLayer.context;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "#fff";
        context.shadowBlur = 3;
        for (let i = 0; i < 1000; i++) {
            let x = Math.floor(Math.random() * canvas.width);
            let y = Math.floor(Math.random() * canvas.height);
            let radius = Math.random() * 1 + 0.1;
            context.beginPath();
            context.arc(x, y, radius, 0, 2 * Math.PI);
            context.fill();
        }

    }


    renderStatic() {

        let canvas = this.layers.static.canvas;
        let context = this.layers.static.context;
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Set Star Color
        let r = this.state.system.star.color.r;
        let g = this.state.system.star.color.g;
        let b = this.state.system.star.color.b;
        let color = "rgba(" + r + ", " + g + ", " + b + ",1)";

        // set globals for this map
        this.starRadius = Math.min(Math.max(200 * this.state.system.star.radius.factor, 2000), 400);
        if (this.state.system.star.class == "Neutron") this.starRadius = 100;
        if (this.state.system.star.class == "White Dwarf") this.starRadius = 100;
        this.orbitSpacing = ((canvas.width / 2) - 1000 - this.starRadius) / this.state.system.orbits.length;


        context.textAlign = "center";
        context.font = "15px 'Alegreya Sans SC'";
        context.strokeStyle = "rgba(255,255,255,0.5)";
        context.shadowColor = '#fff';
        context.shadowBlur = 0;

        // Draw starlight glow
        if (this.state.system.star.glow) {
            let glowStart = "rgba(" + r + ", " + g + ", " + b + ",0.2)";
            let glowEnd = "rgba(" + r + ", " + g + ", " + b + ",0)";
            let glow = context.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 2);
            glow.addColorStop(0, glowStart);
            glow.addColorStop(1, glowEnd);
            context.fillStyle = glow;
            context.beginPath();
            context.arc(canvas.width / 2, canvas.height / 2, canvas.width, 0, 2 * Math.PI)
            context.fill();
        }


        if (this.state.system.star.jets) {

            context.shadowColor = "rgba(255,255,255,1)";
            context.shadowBlur = 20;
            let jetStart = "rgba(" + r + ", " + g + ", " + b + ",0.75)";
            let jetEnd = "rgba(" + r + ", " + g + ", " + b + ",0.0)";
            let jet = context.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 4);
            jet.addColorStop(0, jetStart);
            jet.addColorStop(1, jetEnd);
            context.fillStyle = jet;
            context.beginPath();
            context.moveTo(canvas.width / 2, canvas.height / 2)
            context.arc(canvas.width / 2, canvas.height / 2, canvas.width / 4, Math.PI * 0.51, Math.PI * 0.54);
            context.lineTo(canvas.width / 2, canvas.height / 2)
            context.fill();
            context.beginPath();
            context.moveTo(canvas.width / 2, canvas.height / 2)
            context.arc(canvas.width / 2, canvas.height / 2, canvas.width / 4, Math.PI * 1.51, Math.PI * 1.54);
            context.lineTo(canvas.width / 2, canvas.height / 2)
            context.fill();

            jetStart = "rgba(255,255,255,0.75)";
            jetEnd = "rgba(255,255,255,0.0)";
            jet = context.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 12);
            jet.addColorStop(0.1, jetStart);
            jet.addColorStop(1, jetEnd);
            context.fillStyle = jet;
            context.beginPath();
            context.moveTo(canvas.width / 2, canvas.height / 2)
            context.arc(canvas.width / 2, canvas.height / 2, canvas.width / 12, Math.PI * 0.51, Math.PI * 0.54);
            context.lineTo(canvas.width / 2, canvas.height / 2)
            context.fill();
            context.beginPath();
            context.moveTo(canvas.width / 2, canvas.height / 2)
            context.arc(canvas.width / 2, canvas.height / 2, canvas.width / 12, Math.PI * 1.51, Math.PI * 1.54);
            context.lineTo(canvas.width / 2, canvas.height / 2)
            context.fill();

            context.filter = "none";
        }


        // Set fill if any otherwise white (most stars are white, exotics may have a fill)
        if (this.state.system.star.fill) {
            let r = this.state.system.star.fill.r;
            let g = this.state.system.star.fill.g;
            let b = this.state.system.star.fill.b;
            context.fillStyle = "rgba(" + r + ", " + g + ", " + b + ",1)";
        } else {
            context.fillStyle = "#fff";
        }


        // Set shadow and blur for Star Corona
        context.shadowColor = color;
        context.shadowBlur = this.starRadius * 0.5;
        context.strokeStyle = color;
        context.lineWidth = 1;

        // Draw Star
        context.beginPath();
        context.arc(canvas.width / 2, canvas.height / 2, this.starRadius, 0, 2 * Math.PI)
        context.fill();
        context.stroke();

        // reset colors and sizes for orbits and objects
        context.strokeStyle = "#fff";
        context.shadowColor = "#fff";
        context.lineWidth = 2;

        // Iterate each orbit and render if an object is present
        for (let i = 0; i < this.state.system.orbits.length; i++) {
            let orbit = this.state.system.orbits[i];
            if (orbit.object) {

                let orbitRadius = (this.orbitSpacing * (i + 1)) + 500;
                context.fillStyle = "#fff";
                context.shadowBlur = 20;

                context.lineWidth = 3;
                context.beginPath();
                context.arc(canvas.width / 2, canvas.height / 2, orbitRadius, 0, 2 * Math.PI)
                context.stroke();

                // context.beginPath();
                // context.arc(canvas.width/2 + orbitRadius, canvas.height / 2, objectRadius, 0, 2 * Math.PI)
                // context.fill();
            }
        }
    }

    renderNames() {
        let canvas = this.layers.names.canvas;
        let context = this.layers.names.context;

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.textAlign = "center";
        context.font = "15px 'Alegreya Sans SC'";
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeStyle = "rgba(255,255,255,0.5)";
        context.fillStyle = "#fff";
        context.shadowColor = '#000';
        context.shadowBlur = 5;

        if (this.state.scale > 0.5) {
            for (let system of gameData.systems) {
                if (system.name) {
                    if (!this.state.viewing || (this.state.viewing && system.owner == this.state.viewing.owner) || (this.state.viewing && !this.state.viewing.owner)) {
                        context.beginPath();
                        context.fillText(system.name, system.position.x, system.position.y + 25)
                    }
                }
            }
        }

    }


    drawSystemInfo(context) {
        let systems = gameData.systems;
        context.shadowColor = "#fff"

        for (let system of systems) {
            if (system.owner) {
                let color = system.owner.color;
                if (!color) console.log(system);

                let infoRadius = (5 * system.connections.length) + 10;
                context.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
                context.beginPath();
                context.arc(system.position.x, system.position.y, infoRadius, 0, 2 * Math.PI);
                context.fill();

                if (this.state.viewing && system.owner == this.state.viewing.owner) {
                    context.shadowBlur = 10;
                    context.lineWidth = this.state.scale < 0.7 ? 2 : 1;
                    context.strokeStyle = "#fff";
                    context.stroke();
                }

                context.shadowBlur = 0;

                for (let connection of system.connections) {
                    if (connection.owner == system.owner) {
                        context.strokeStyle = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
                        context.lineWidth = 13;
                        context.beginPath();
                        context.moveTo(system.position.x, system.position.y)
                        context.lineTo(connection.position.x, connection.position.y)
                        context.stroke();
                    }
                }


            }
        }
    }

    renderDynamic() {
        const renderStartTime = performance.now();
        if (ui.views.SystemMap.isOpen) {
            let canvas = this.layers.dynamic.canvas;
            let context = this.layers.dynamic.context;
            let pi = Math.PI;


            context.clearRect(0, 0, canvas.width, canvas.height);
            context.shadowColor = "#fff";
            context.shadowBlur = 20;
            context.fillStyle = "#fff"
            context.textAlign = "center";
            context.font = "30px 'Alegreya Sans SC'";

            for (let i = 0; i < this.state.system.orbits.length; i++) {
                let orbit = this.state.system.orbits[i];
                if (orbit.object) {

                    let object = orbit.object;

                    context.fillStyle = "#fff";
                    context.strokeStyle = "#fff";
                    context.shadowColor = "#fff";
                    context.shadowOffsetX = 0;
                    context.shadowOffsetY = 0;
                    context.shadowBlur = 20;
                    

                    let objectRadius = Math.max(Math.min(30 * object.radius.factor, 100), 10);
                    let orbitRadius = (this.orbitSpacing * (i + 1)) + 500;

                    let originX = canvas.width / 2;
                    let originY = canvas.height / 2;

                    let theta = object.angle * (pi / 180);
                    let objectX = originX + orbitRadius * Math.cos(theta)
                    let objectY = originY + orbitRadius * Math.sin(theta);

                    // object.angle -= 0.05 / (i + 1);
                    // object.angle += object.angle < 0 ? 360 : 0;

                    context.fillStyle = "#fff";
                    context.beginPath();
                    context.arc(objectX, objectY, objectRadius, 0, 2 * Math.PI)
                    context.fill();

                    if (object.rings.length) {
                        context.beginPath();
                        context.ellipse(objectX, objectY, objectRadius * 1.75, objectRadius * 0.5, 0.05 * Math.PI, 0, 2 * Math.PI);
                        // context.arc(objectX, objectY, objectRadius+20, 0, 2 * Math.PI)
                        context.fill();

                    }

                    
                    let labelDist = 50;

                    if (object && object == this.state.viewing) {

                        if (this.state.tracking) {
                            let x = -canvas.width / 2 - (objectX - canvas.width / 2);
                            let y = -canvas.height / 2 - (objectY - canvas.height / 2);
                            let deltaX = x - this.state.translate.x;
                            let deltaY = y - this.state.translate.y;
                            this.state.translate.x = x;
                            this.state.translate.y = y;
                            this.state.backgroundTranslate.x -= -deltaX * 0.05;
                            this.state.backgroundTranslate.y -= -deltaY * 0.05;
                            // this.layerSurface.style.transformOrigin = `${-x}px ${-y}px`;
                        }

                        context.lineWidth = 10;

                        /*
                        let pointerX = originX + (orbitRadius - objectRadius - 50) * Math.cos(theta)
                        let pointerY = originY + (orbitRadius - objectRadius - 50) * Math.sin(theta);
                        context.beginPath();
                        context.moveTo(originX, originY);
                        context.lineTo(pointerX, pointerY);
                        context.stroke();
                        */

                        context.beginPath()
                        context.arc(objectX, objectY, objectRadius + 50, 0, 2 * Math.PI);
                        context.stroke()

                        labelDist = 90

                    } else {

                    }

                    context.lineWidth = 1;
                    context.beginPath();
                    context.strokeText(object.name, objectX, objectY + objectRadius + labelDist)
                    context.fillText(object.name, objectX, objectY + objectRadius + labelDist)

                }
            }
        }
        const renderEndTime = performance.now();
        gameData.performance.render.systemMap = (renderEndTime - renderStartTime).toFixed(2);

        window.requestAnimationFrame(() => {
            this.renderDynamic();
        })
    }

    reset() {
        this.state.viewing = false;
        this.state.tracking = false;
        this.state.scale = 0.5;
        this.state.translate = {
            x: -config.systemMap.width / 2,
            y: -config.systemMap.height / 2
        };
        this.state.backgroundTranslate = {
            x: -window.innerWidth / 2,
            y: -window.innerHeight / 2
        };
    }

    render(system) {
        this.state.system = system;

        this.reset()


        this.updateInfo();
        this.renderStatic();
        // this.renderNames();
        this.renderDynamic();
    }

}